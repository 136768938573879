import React from "react";
import { partnerReferralTitleCopy } from "./partner-referral-screen.consts";

export const getPartnerReferralTitleCopy = (
  drivaNewDesignEnabled: boolean,
  firstName: string | null | undefined
) => {
  if (!drivaNewDesignEnabled) return partnerReferralTitleCopy;
  else if (firstName) {
    return (
      <span>
        {firstName}, we can&apos;t provide you with a loan.
        <br />
        <strong>But you still have some really good options.</strong>
      </span>
    );
  } else {
    return (
      <span>
        We can&apos;t provide you with a loan.
        <br />
        <strong>But you still have some really good options.</strong>
      </span>
    );
  }
};
