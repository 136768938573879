import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import React from "react";

import { Application } from "../../../../application";
import { ID_TYPES } from "../../../../application.utils";
import style from "../../../multi-page-application-form.scss";
import { SecurityMsg } from "../../../security-msg/security-msg";
import idDocsStyle from "../id-documents.scss";
import { DocumentSubmit } from "../status-and-submit/document-submit";
import { passportCountryList } from "./passport.constants";
import { AppFormPages } from "../../../../../shared/analytics/analytics.consts";
import { sendAppFormViewedEvent } from "../../../multi-page-application-form.utils";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  setValue: UseFormTools<Application>["setValue"];
  formState: UseFormTools<Application>["formState"];
}

export const Passport: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  setValue,
  formState,
  location,
}) => {
  const isDisabled =
    !formData.passportCountry ||
    !formData.passportNumber ||
    formState.passportNumber.errors.length > 0;

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.PASSPORT);
  }, []);

  return (
    <div className="multi-page-application">
      <style jsx>{style}</style>
      <style jsx>{idDocsStyle}</style>

      <div className="form-contents">
        <div className="heading-wrapper">
          <h2 className="heading">Passport details</h2>
        </div>

        <SecurityMsg />

        <div className="input-wrapper">
          <Field
            id={`form_application_input_passport_country_option`}
            label="Country of issue"
            type="select"
            placeholder="Select an option"
            options={passportCountryList}
            required
            {...fieldAdapter({
              name: "passportCountry",
              required: "Please select a country of issue",
            })}
          />

          <Field
            id={`form_application_input_passport_number`}
            label="Passport number"
            placeholder="Enter Passport number"
            type="text"
            required
            {...fieldAdapter({
              name: "passportNumber",
              required: "Please enter your passport number",
              maxLength: [
                9,
                "Passport number cannot be more than 9 characters long",
              ],
              pattern: [/^[a-zA-Z0-9]+$/, "Passport number is invalid"],
            })}
          />
        </div>
      </div>
      <DocumentSubmit
        isDisabled={isDisabled}
        location={location}
        setValue={setValue}
        formData={formData}
        idType={ID_TYPES.PASSPORT}
      />
    </div>
  );
};
