import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { isBrowser, UseFormTools } from "@wisr/common";
import { Button, Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { ContextCard } from "../../../../context-card/context-card";
import { ContextCardApplicationCopies } from "../../../../context-card/context-card.constants";
import { AppFormPages } from "../../../../shared/analytics/analytics.consts";
import { Application } from "../../../application";
import { HelpCallout } from "../../help-callout/help-callout";
import { useIsEditing } from "../../hooks/use-is-editing";
import { InfoModal } from "../../info-modal/info-modal";
import style from "../../multi-page-application-form.scss";
import { sendAppFormViewedEvent } from "../../multi-page-application-form.utils";
import dependantsStyle from "./dependants.scss";
import { useSaveLoanApplicationProgress } from "../../../../redux/loan-application/loan-application.hooks";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  setValue: UseFormTools<Application>["setValue"];
  formState: UseFormTools<Application>["formState"];
}

export const Dependants: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  setValue,
  formState,
  location,
}) => {
  const saveProgress = useSaveLoanApplicationProgress(formData);
  const [infoModal, setInfoModal] = React.useState(false);

  const isEditing = useIsEditing(location);
  const isDisabled =
    !formData.numberOfFinancialDependents ||
    formState.numberOfFinancialDependents.errors.length > 0;

  const requirementsAndObjectives = isBrowser()
    ? window.sessionStorage.getItem("requirementsAndObjectives")
    : null;

  const nextPage = (noDependents?: boolean) => {
    saveProgress();
    if (noDependents) {
      setValue("numberOfFinancialDependents", "0");
    }
    if (isEditing) {
      navigate("/application/review-your-details");
    } else {
      navigate("/application/id-documents");
    }
  };

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.ABOUT_YOU);
  }, []);

  React.useEffect(() => {
    if (typeof requirementsAndObjectives === "string") {
      setValue("requirementsAndObjectives", requirementsAndObjectives.slice());
      window.sessionStorage.removeItem("requirementsAndObjectives");
    }
  }, [requirementsAndObjectives, setValue]);

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{dependantsStyle}</style>

        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">
              How many financial dependants do you have?
            </h2>
          </div>
          <HelpCallout
            text={dependantsModalHeading}
            setInfoModal={setInfoModal}
          />
          <div className="input-wrapper">
            <Field
              type="number"
              label="Dependants"
              {...fieldAdapter({
                name: "numberOfFinancialDependents",
                required: "Number of financial dependents is required",
                min: [0],
                max: [10, "Number of financial dependents must be 10 or less"],
              })}
            />
          </div>
        </div>
        <ContextCard
          copy={ContextCardApplicationCopies.DEPEND_ON_US}
          form="application"
        />

        <div className="button-wrapper">
          <Button button="secondary" theme="navy" size="large" fullWidth={true}>
            <button className="next-page-button" onClick={() => nextPage(true)}>
              i don&apos;t have any
            </button>
          </Button>
          <Button
            button="primary"
            theme="navy"
            size="large"
            fullWidth={true}
            disabled={isDisabled}
          >
            <button
              className="next-page-button"
              onClick={() => nextPage()}
              disabled={isDisabled}
            >
              {isEditing ? "back to review" : "next"}
            </button>
          </Button>
        </div>
      </div>
      <InfoModal
        infoModal={infoModal}
        setInfoModal={setInfoModal}
        heading={dependantsModalHeading}
        description={dependantsModalDescription}
      />
    </>
  );
};

export const dependantsModalHeading = "What is a financial dependant?";
export const dependantsModalDescription =
  "Financial dependants are any children or adults who live with you and you care for financially.";
