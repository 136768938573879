import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Button, Field, Icon } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { Application } from "../../../application";
import { useIsEditing } from "../../hooks/use-is-editing";
import style from "../../multi-page-application-form.scss";
import { incomeSourceOptions } from "./other-income-list.constants";
import otherIncomeListStyle from "./other-income-list.scss";
import {
  addIncomeListItem,
  deleteIncomeListItem,
  getOtherIncomeInitialValues,
  isOtherIncomeButtonDisabled,
} from "./other-income-list.utils";
import { paymentFrequencyOptions } from "../../../application-form.constants";
import { useSaveLoanApplicationProgress } from "../../../../redux/loan-application/loan-application.hooks";
import { isValidLengthWithoutDecimals } from "../../../../redux/loan-application/loan-application.utils";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  formState: UseFormTools<Application>["formState"];
  setValue: UseFormTools<Application>["setValue"];
}

export const OtherIncomeList: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  formState,
  setValue,
  location,
}) => {
  const saveProgress = useSaveLoanApplicationProgress(formData);

  const [otherIncomeList, setOtherIncomeList] = React.useState(
    getOtherIncomeInitialValues(formData)
  );
  const isEditing = useIsEditing(location);
  const isDisabled = isOtherIncomeButtonDisabled(
    otherIncomeList,
    formData,
    formState
  );

  const nextPage = () => {
    saveProgress();
    if (isEditing) {
      navigate("/application/review-your-details");
    } else {
      navigate("/application/your-spending");
    }
  };

  return (
    <div className="multi-page-application">
      <style jsx>{style}</style>
      <style jsx>{otherIncomeListStyle}</style>

      <div className="form-contents">
        <div className="heading-wrapper">
          <h2 className="heading">
            Tell us about your other sources of income
          </h2>
        </div>
        <div className="input-wrapper">
          {otherIncomeList.map((incomeItem, index) => {
            const nextListItem = otherIncomeList[index + 1];
            const hasAddIncome = nextListItem && nextListItem.active === false;
            const otherIncomeLabel =
              incomeSourceOptions.find(
                (incomeSource) =>
                  incomeSource.value === formData[incomeItem.source]
              )?.label ?? "Income source";

            return (
              <div
                key={`${incomeItem.id}${incomeItem.active}`}
                className={`income-item-${incomeItem.active}`}
              >
                {formData[incomeItem.source] ? (
                  <div className="income-source-title">
                    <h3 className="other-income-label">{otherIncomeLabel}</h3>
                    <div
                      className="delete-income-item"
                      onClick={() => {
                        deleteIncomeListItem(
                          incomeItem.id,
                          otherIncomeList,
                          setOtherIncomeList,
                          setValue,
                          formData
                        );
                      }}
                    >
                      <Icon
                        name="action-trash-outline"
                        size="sm"
                        legacy={false}
                      />
                    </div>
                  </div>
                ) : (
                  <Field
                    type="select"
                    label="Income source"
                    options={incomeSourceOptions}
                    placeholder="Select"
                    {...fieldAdapter({
                      name: incomeItem.source,
                      condition: incomeItem.active,
                    })}
                  />
                )}

                {formData[incomeItem.source] && (
                  <>
                    <div className="field-pair">
                      <Field
                        type="currency"
                        label="Income (before tax)"
                        layout="pair"
                        hasCommas={true}
                        {...fieldAdapter({
                          name: incomeItem.amount,
                          required: "Income amount is required",
                          min: [1],
                          validate: (value) =>
                            isValidLengthWithoutDecimals(value, 10) ||
                            "Income amount cannot be more than 10 digits (including 2 decimals)",
                          condition: incomeItem.active,
                        })}
                      />
                      <Field
                        type="select"
                        label="Frequency"
                        layout="pair"
                        options={paymentFrequencyOptions}
                        placeholder="Select"
                        {...fieldAdapter({
                          name: incomeItem.frequency,
                          required: "Income frequency is required",
                          condition: incomeItem.active,
                        })}
                      />
                    </div>
                    <hr />
                    {hasAddIncome && (
                      <div
                        className="add-income"
                        onClick={() => {
                          addIncomeListItem(
                            index + 1,
                            otherIncomeList,
                            setOtherIncomeList
                          );
                        }}
                      >
                        <Icon
                          name="action-add-circle"
                          size="sm"
                          legacy={false}
                        />
                        <p>Add another income</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="button-wrapper">
        <Button
          button="primary"
          theme="navy"
          size="large"
          fullWidth={true}
          disabled={isDisabled}
        >
          <button
            className="next-page-button"
            onClick={() => nextPage()}
            disabled={isDisabled}
          >
            {isEditing ? "back to review" : "next"}
          </button>
        </Button>
      </div>
    </div>
  );
};
