import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Button, Field, Fieldset } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { AutoFillFix } from "../../../../../shared/application/auto-fill-fix/auto-fill-fix.component";
import { isLessThan6MonthsAt } from "../../../../../shared/application/form.utils";

import { Application } from "../../../../application";
import { validPhoneNumberRegex } from "../../../../application-form.constants";
import { HelpCallout } from "../../../help-callout/help-callout";
import { useIsEditing } from "../../../hooks/use-is-editing";
import { InfoModal } from "../../../info-modal/info-modal";
import style from "../../../multi-page-application-form.scss";
import {
  employmentStatusOptions,
  occupationOptions,
} from "../employment-and-income.constants";
import previousEmploymentStyle from "./previous-employment.scss";
import { AppFormPages } from "../../../../../shared/analytics/analytics.consts";
import { sendAppFormViewedEvent } from "../../../multi-page-application-form.utils";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
}

export const PreviousEmployment = ({
  fieldAdapter,
  formData,
  location,
}: PropTypes) => {
  const [infoModal, setInfoModal] = React.useState(false);
  const isEditing = useIsEditing(location);
  const modalHeading = "Why do we ask this?";
  const modalDescription = (
    <>
      <p>
        If you have worked for your current employer less than 6 months, we will
        need to know some details about your previous employer.
      </p>
      <p>
        Wisr may contact your previous employer to verify your work history.
      </p>
    </>
  );

  const lessThan6MonthsAtEmployer = isLessThan6MonthsAt(
    formData.yearsAtEmployer,
    formData.monthsAtEmployer
  );

  const validateContactNumber = (value: string) => {
    return (
      !value ||
      validPhoneNumberRegex.test(value) ||
      "Employer contact number must have 10 digits, this means landlines must include an area code"
    );
  };

  const nextPage = () => {
    if (isEditing) {
      navigate("/application/review-your-details");
    } else {
      if (
        formData.maritalStatus === "married" ||
        formData.maritalStatus === "de-facto"
      ) {
        navigate("/application/partners-income");
      } else {
        navigate("/application/other-income");
      }
    }
  };

  const isDisabled =
    !formData.previousEmploymentStatus ||
    !formData.yearsAtPreviousEmployer ||
    !formData.monthsAtPreviousEmployer ||
    !formData.previousEmployerName ||
    !formData.previousOccupation ||
    (!!formData.previousEmploymentContactNumber &&
      !validPhoneNumberRegex.test(formData.previousEmploymentContactNumber));

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.PREVIOUS_EMPLOYMENT);
  }, []);

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{previousEmploymentStyle}</style>

        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">Your previous employment</h2>
          </div>

          <HelpCallout text={modalHeading} setInfoModal={setInfoModal} />

          <div className="input-wrapper">
            <Fieldset>
              <AutoFillFix
                section="previous-employer"
                safeTypes={["name", "tel-national"]}
              />

              <Field
                type="select"
                label="Previous employment status"
                options={employmentStatusOptions}
                placeholder="Select..."
                {...fieldAdapter({
                  name: "previousEmploymentStatus",
                  required: "Previous employment status is required",
                  condition: lessThan6MonthsAtEmployer,
                })}
              ></Field>

              <Field
                layout="pair"
                type="number"
                label="Years at employer"
                {...fieldAdapter({
                  name: "yearsAtPreviousEmployer",
                  condition: lessThan6MonthsAtEmployer,
                  validate: (value, form) =>
                    form.monthsAtPreviousEmployer !== "" ||
                    form.yearsAtPreviousEmployer !== "" ||
                    "Years at employer is required",
                  min: [0],
                  max: [99],
                })}
              ></Field>

              <Field
                layout="pair"
                type="number"
                label="Months at employer"
                {...fieldAdapter({
                  name: "monthsAtPreviousEmployer",
                  condition: lessThan6MonthsAtEmployer,
                  validate: (value, form) =>
                    form.monthsAtPreviousEmployer !== "" ||
                    form.yearsAtPreviousEmployer !== "" ||
                    "Months at employer is required",
                  min: [0, "Months at employer is required"],
                  max: [11, "Months at employer must be 11 or less"],
                })}
              ></Field>

              <Field
                layout="single"
                label="Employer name"
                type="text"
                autoComplete="section-previous-employer name"
                {...fieldAdapter({
                  name: "previousEmployerName",
                  condition: lessThan6MonthsAtEmployer,
                  required: "Current employer name is required",
                })}
              ></Field>

              <Field
                layout="single"
                label="Occupation"
                type="select"
                options={occupationOptions}
                placeholder="Select..."
                {...fieldAdapter({
                  name: "previousOccupation",
                  condition: lessThan6MonthsAtEmployer,
                  required: "Current occupation is required",
                })}
              ></Field>

              <Field
                layout="single"
                label="Employer contact number"
                type="mobile"
                autoComplete="section-previous-employer tel-national"
                {...fieldAdapter({
                  name: "previousEmploymentContactNumber",
                  condition: lessThan6MonthsAtEmployer,
                  validate: validateContactNumber,
                })}
              ></Field>
            </Fieldset>
          </div>

          <div className="button-wrapper">
            <Button
              button="primary"
              theme="navy"
              size="large"
              fullWidth={true}
              disabled={isDisabled}
            >
              <button
                className="next-page-button"
                onClick={() => nextPage()}
                disabled={isDisabled}
              >
                {isEditing ? "back to review" : "next"}
              </button>
            </Button>
          </div>
        </div>
      </div>
      <InfoModal
        infoModal={infoModal}
        setInfoModal={setInfoModal}
        heading={modalHeading}
        description={modalDescription}
      />
    </>
  );
};
