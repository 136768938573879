import React from "react";
import loginImage from "../../assets/images/application/login-illustration.svg";
import wisrLogo from "../../assets/images/wisr-logo-white.svg";
import { Button, Field, Icon, StatusCallout, Wrapper } from "@wisr/react-ui";
import loginStyle from "./application-login.scss";
import { SupportModal } from "../support-modal/support-modal";
import { useDispatch } from "react-redux";
import { fetchApplicationLogin } from "./application-login.thunk";
import { useGetApplicationLoginState } from "./application-login.hooks";
import { ApplicationLoginInitial } from "./application-login.consts";
import { createValidator, useForm } from "@wisr/common";
import { FetchStatus } from "../../shared/redux.consts";
import { openForgotPasswordPage, storeUser } from "../../auth/auth.utils";
import { navigate } from "gatsby";
import { decodeToken, isValidEmail } from "./application-login.utils";
import { useGetApplicationHeaderState } from "../../redux/application-header/application-header.hooks";

export const ApplicationLogin: React.FC = () => {
  const [supportModal, setSupportModal] = React.useState(false);
  const dispatch = useDispatch();
  const applicationHeaderEmail =
    useGetApplicationHeaderState().applicationHeader?.email;
  const applicationLoginState = useGetApplicationLoginState();
  const loginLoading = applicationLoginState.status === FetchStatus.LOADING;
  const loginFailed = applicationLoginState.loginResponse?.failed === true;
  const loginSuccess = applicationLoginState.loginResponse?.failed === false;
  const loginError =
    !!applicationLoginState.error &&
    applicationLoginState.status === FetchStatus.FAILED;
  const accessToken =
    applicationLoginState.loginResponse?.tokenDetails?.accessToken;
  const refreshToken =
    applicationLoginState.loginResponse?.tokenDetails?.refreshToken;

  const { fieldAdapter, submitAdapter, formHasErrors, setValue, formData } =
    useForm(ApplicationLoginInitial);
  const buttonDisabled =
    formHasErrors() ||
    loginLoading ||
    loginSuccess ||
    (!formData.email && !applicationHeaderEmail) ||
    !formData.password;

  const onSubmit = submitAdapter((result: boolean, submittedFormData) => {
    if (result) {
      dispatch(
        fetchApplicationLogin({
          email: applicationHeaderEmail ?? submittedFormData.email,
          password: submittedFormData.password,
        })
      );
    }
    return Promise.resolve();
  });

  React.useEffect(() => {
    if (loginFailed || loginError) {
      setValue("password", "");
    }
  }, [loginError, loginFailed, loginLoading, setValue]);

  React.useEffect(() => {
    if (loginSuccess && accessToken && refreshToken) {
      const tokenObject = decodeToken(accessToken);

      storeUser(
        refreshToken,
        tokenObject.auth_time,
        tokenObject.sub,
        "Bearer",
        accessToken
      );
      navigate("/login");
    }
  }, [accessToken, loginSuccess, refreshToken]);

  return (
    <Wrapper>
      <style jsx>{loginStyle}</style>
      <div className="login-page">
        <div className="login-header">
          <img src={wisrLogo} className="wisr-logo" />
          <span className="support-icon" onClick={() => setSupportModal(true)}>
            <Icon name="action-support-chat" size="md" legacy={false} />
          </span>
        </div>
        <div className="login-body">
          <form noValidate className="login-form" onSubmit={onSubmit}>
            <div className="login-fields">
              <h1>Welcome back!</h1>
              <h2>Log in to continue your loan application.</h2>
              {(loginFailed || loginError) && !loginLoading && (
                <div className="callout-wrapper">
                  <StatusCallout
                    type="error"
                    message="The email or password you entered is incorrect"
                  />
                </div>
              )}
              {!applicationHeaderEmail && (
                <Field
                  type="input"
                  label="Email"
                  autoComplete="email"
                  placeholder="The email you used to sign up"
                  {...fieldAdapter({
                    name: "email",
                    validate: createValidator(
                      isValidEmail,
                      "Please enter a valid email"
                    ),
                  })}
                />
              )}
              <Field
                label="Password"
                type="password"
                placeholder="Your password"
                autoComplete="current-password"
                {...fieldAdapter({
                  name: "password",
                })}
              />
            </div>
            <div className="button-wrapper">
              <Button
                button="primary"
                theme="navy"
                fullWidth
                disabled={buttonDisabled}
              >
                <button type="submit" disabled={buttonDisabled}>
                  Let&rsquo;s go
                </button>
              </Button>
            </div>
            <p className="forgot-password">
              Forgot password?{" "}
              <a onClick={() => openForgotPasswordPage()}>Reset password</a>
            </p>
          </form>
        </div>
        <img src={loginImage} className="login-image" />
      </div>
      <SupportModal
        supportModal={supportModal}
        setSupportModal={setSupportModal}
      />
    </Wrapper>
  );
};
