import { useSelector } from "react-redux";
import { AppStore } from "../../store";
import { ApplicationHeaderState } from "../../types/application-header";
import { selectApplicationHeaderState } from "./application-header.slice";

export const useGetApplicationHeaderState = () => {
  return useSelector<AppStore, ApplicationHeaderState>(
    selectApplicationHeaderState
  );
};
