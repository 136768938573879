import { Button, Modal } from "@wisr/react-ui";
import React from "react";

import style from "./info-modal.scss";

interface PropTypes {
  infoModal: boolean;
  setInfoModal: (arg0: boolean) => void;
  heading: string;
  description: string | React.ReactElement;
  buttonText?: string;
}

export const InfoModal: React.FC<PropTypes> = ({
  infoModal,
  setInfoModal,
  heading,
  description,
  buttonText,
}) => {
  return (
    <Modal
      titleText={heading}
      open={infoModal}
      onClose={/* istanbul ignore next */ () => setInfoModal(false)}
    >
      <div className="info-modal">
        <style jsx>{style}</style>
        {typeof description === "string" ? (
          <p className="description">{description}</p>
        ) : (
          <>{description}</>
        )}
        <div className="button-wrapper">
          <Button theme="navy" fullWidth>
            <button type="button" onClick={() => setInfoModal(false)}>
              {buttonText ?? "Close"}
            </button>
          </Button>
        </div>
      </div>
    </Modal>
  );
};
