import { Icon } from "@wisr/react-ui";
import React from "react";

import style from "./help-callout.scss";

interface PropTypes {
  text: string;
  setInfoModal: (arg0: boolean) => void;
  padding?: "sm" | "md" | "lg";
}

export const HelpCallout: React.FC<PropTypes> = ({
  text,
  setInfoModal,
  padding = "lg",
}) => {
  return (
    <div
      className={`help-wrapper ${padding}`}
      onClick={() => setInfoModal(true)}
    >
      <style jsx>{style}</style>
      <Icon name="action-help-outline" size="sm" legacy={false} />
      <p>{text}</p>
    </div>
  );
};
