import React from "react";

export const requirementsModalHeading = "Why do we ask this?";
export const requirementsModalDescription = (
  <div className="requirements-copy">
    <p>
      As a responsible lender, we have a commitment to ensure that the loan you
      are applying for is suitable to you.
    </p>
    <p>
      <strong>Objectives:</strong>
      <br />
      <strong>What will you be using the money for?</strong> e.g purchase a new
      car, renovate a bathroom
    </p>
    <p>
      <strong>Requirements:</strong>
      <br />
      <strong>
        What specific needs do you have in relation to the loan?
      </strong>{" "}
      e.g. reduce interest rate, or repayments
    </p>
  </div>
);

export const requirementsFieldPlaceholder =
  "Please share here:\n• What will you be using the money for?\n• Any specific needs you have in relation to the loan";
