import { isBrowser, UseFormTools } from "@wisr/common";
import { Accordion } from "@wisr/react-ui";
import React from "react";

import { Application } from "../../../../application";
import style from "../../../multi-page-application-form.scss";
import { EditLink } from "../edit-link/edit-link";
import { getReviewFields, ReviewField } from "../review-fields/review-fields";
import reviewStyle from "../review-your-details.scss";
import { canEditAllFields } from "../review-your-details.utils";
import { useGetLoanOfferState } from "../../../../../redux/loan-offer/loan-offer.hooks";
import { useGetLoanParameters } from "../../../../../loans/parameters/loan-parameters.hooks";
interface ReviewAccordionProps {
  formData: UseFormTools<Application>["formData"];
}
export const ReviewAccordion = ({ formData }: ReviewAccordionProps) => {
  const loanParameters = useGetLoanParameters();
  const loanOfferState = useGetLoanOfferState();
  const loanOfferForApplication = loanOfferState.loanOffers.find(
    (loanOffer) => `${loanOffer.LoanId}` === formData.loanId
  );
  const reviewData: { [char: string]: ReviewField[] | [] } = getReviewFields(
    formData,
    loanParameters.loanPurpose,
    loanOfferForApplication
  );
  const sessionStorageItems =
    isBrowser() && window.sessionStorage.getItem("touchedSections");
  const [touchedSections, setTouchedSections] = React.useState<string[]>(
    sessionStorageItems ? JSON.parse(sessionStorageItems) : ["Your Loan"]
  );

  React.useEffect(() => {
    sessionStorageItems && setTouchedSections(JSON.parse(sessionStorageItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <style jsx>{style}</style>
      <style jsx>{reviewStyle}</style>

      {touchedSections &&
        Object.keys(reviewData).map((sectionKey) => {
          return (
            <Accordion
              title={sectionKey}
              startOpen={touchedSections.includes(sectionKey)}
              key={sectionKey}
            >
              {canEditAllFields(reviewData[sectionKey])
                ? reviewData[sectionKey].map((data, i) => (
                    <div className="formdata-review-field" key={i}>
                      <span className="label">{data.label}</span>
                      <div>
                        <span className="value">{data.value}</span>
                        {i === 0 && (
                          <EditLink
                            data={data}
                            sectionKey={sectionKey}
                            formData={formData}
                          />
                        )}
                      </div>
                    </div>
                  ))
                : reviewData[sectionKey].map((data, i) => (
                    <div className="formdata-review-field" key={i}>
                      <span className="label">{data.label}</span>
                      <div>
                        {typeof data.value === "string" ? (
                          <span className="value">{data.value}</span>
                        ) : (
                          data.value
                        )}

                        <EditLink
                          data={data}
                          sectionKey={sectionKey}
                          formData={formData}
                        />
                      </div>
                    </div>
                  ))}
            </Accordion>
          );
        })}
    </>
  );
};
