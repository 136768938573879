// todo: The early exit on common values is a huge issue for the hooks on this page.
// To fix will probably require some solid refactoring
/* eslint-disable */
import { isBrowser, isNotEmpty, useForm } from "@wisr/common";
import { Button, Loader, Modal } from "@wisr/react-ui";
import React from "react";
import { useDispatch } from "react-redux";
import { getPathFromLocation, useQueryString } from "../shared/gatsby.utils";
import { GatsbyPageProps } from "../types/gatsby";
import { Application } from "./application";
import style from "./application-form.scoped.scss";
import { applicationGetContinueTokenAction } from "./application-form.actions";
import { useApplicationForm } from "./application-form.hooks";
import {
  getApplicationFromActive,
  sendLoanApplicationAmplitudeEvent,
} from "./application.utils";
import { MultiPageApplicationForm } from "./multi-page-application-form/multi-page-application-form";
import {
  ApplicationEvents,
  LoanStatus,
} from "../shared/analytics/analytics.consts";
import { ApplicationFormPageQuery } from "../../generated/graphql-types";
import { ApplicationLayout } from "../layout/application/application-layout.component";
import { getUser } from "../auth/auth.utils";
import { fetchApplicationHeader } from "../redux/application-header/application-header.thunk";
import { StartApplication } from "./multi-page-application-form/form-pages/start-application/start-application";
import { fetchLoanParameters } from "../loans/parameters/loan-parameters.thunk";
import {
  useGetLoanParameters,
  useGetLoanParametersStatus,
} from "../loans/parameters/loan-parameters.hooks";
import { FetchStatus } from "../shared/redux.consts";
import { ApplicationLogin } from "./login/application-login";
import { ApplicationCreateAccount } from "./create-account/create-account";
import { fetchLoanOffer } from "../redux/loan-offer/loan-offer.thunk";
import { useGetLoanOfferState } from "../redux/loan-offer/loan-offer.hooks";
import {
  useGetIncompleteApplications,
  useGetLoans,
  useGetWisrApplicationId,
} from "../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.hooks";
import { loanBorrowingGetAction } from "../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.actions";
import { fetchLoanApplication } from "../redux/loan-application/loan-application.thunk";
import { useGetLoanApplicationState } from "../redux/loan-application/loan-application.hooks";
import {
  FORM_ERROR_TITLE,
  UNEXPECTED_ERROR_TITLE,
} from "./application-form.constants";
import { ErrorMessage } from "../types/loan-application";
import { BRAND_WEBSITE } from "../shared/url.consts";
import { getPurposeDescriptionByKey } from "./multi-page-application-form/multi-page-application-form.utils";
import { quoteAmplitudeHelper } from "../shared/analytics/analytics.utils";
import { ApplicationSubmitted } from "./multi-page-application-form/form-pages/application-submitted/application-submitted";
import { PartnerReferralScreen } from "./multi-page-application-form/form-pages/partner-referral/partner-referral-screen";
import { useGetWisrProfile } from "../redux/wisr-profile/wisr-profile.hooks";
import { wisrProfileGetAction } from "../redux/wisr-profile/wisr-profile.actions";
import { clearLoanApplicationErrors } from "../redux/loan-application/loan-application.slice";
import { useFlags } from "launchdarkly-react-client-sdk";

type Props = GatsbyPageProps<void, ApplicationFormPageQuery>;

export const ApplicationFormComponent = ({ data, location }: Props) => {
  if (!data || !data.commonValues) {
    return <p>No drop down values</p>; // can we get rid of this if statement?
  }
  const dispatch = useDispatch();
  const params = useQueryString(location);
  const { continueToken } = useApplicationForm(); // can be removed with all PACE API requests
  const loanApplicationState = useGetLoanApplicationState();
  const loanParametersStatus = useGetLoanParametersStatus();
  const { loanPurpose } = useGetLoanParameters();
  const loans = useGetLoans();
  const applications = useGetIncompleteApplications();
  const loanOfferState = useGetLoanOfferState();
  const wisrProfile = useGetWisrProfile();
  const { drivaNewDesignEnabled } = useFlags();
  const [errorModal, setErrorModal] = React.useState("");
  const [formErrors, setFormErrors] = React.useState<string[]>([]);
  const [submissionErrors, setSubmissionErrors] = React.useState<
    ErrorMessage[]
  >([]);
  const isFormError = errorModal === FORM_ERROR_TITLE;

  const sessionLoanId = isBrowser()
    ? window.sessionStorage.getItem("loanId")
    : null;
  const email = params.email;
  const wisrApplicationId = params.wisrapplicationid;
  const firstName = params.firstname;
  const loanIdParam = params.loanid;
  const isStartApplicationPage =
    typeof email === "string" &&
    typeof wisrApplicationId === "string" &&
    typeof firstName === "string";
  const currentLoanId =
    typeof loanIdParam === "string" ? loanIdParam : sessionLoanId;

  const applicationDetails = getApplicationFromActive(
    applications,
    currentLoanId
  );
  const applicationIdFromQuoteId = useGetWisrApplicationId();

  const pagePath = getPathFromLocation(location);
  const loginPage = pagePath === "login";
  const createAccountPage = pagePath === "create-account";
  const submittedPage = pagePath === "submitted";
  const partnerReferralPage = pagePath === "partner-referral";

  React.useEffect(() => {
    if (formErrors.length > 0) {
      setErrorModal(FORM_ERROR_TITLE);
    } else if (
      Array.isArray(loanApplicationState.error) &&
      loanApplicationState.error.length > 0
    ) {
      setErrorModal(UNEXPECTED_ERROR_TITLE);
      setSubmissionErrors(loanApplicationState.error);
      quoteAmplitudeHelper.sendEvent(ApplicationEvents.SERVER_ERROR, {
        Type: "Loan application state",
        Page: window.location.href,
        "Loan id":
          loanApplicationState.loanApplication?.loanId ??
          "Loan application not loaded",
        Info: loanApplicationState.error
          .map((error) => {
            const formattedErrorMessage = error.errorMessage.join(", ");
            const sanitizedErrorMessage = formattedErrorMessage
              .replace(/\\/g, "")
              .replace(/'/g, "");
            return `${error.fieldName}: ${sanitizedErrorMessage}`;
          })
          .join("\n"),
      });
    }
  }, [formErrors, loanApplicationState.error]);

  React.useEffect(() => {
    if (loans === undefined && getUser()) {
      dispatch(loanBorrowingGetAction());
    }
  }, [loans, dispatch]);

  React.useEffect(() => {
    if (typeof loanIdParam === "string" && isBrowser()) {
      window.sessionStorage.setItem("loanId", loanIdParam);
    }
  }, [loanIdParam]);

  React.useEffect(() => {
    const isLoanOfferMissing =
      loanOfferState.loanOffers.length === 0 ||
      !loanOfferState.loanOffers.find(
        (offer) => `${offer.LoanId}` === currentLoanId
      );
    if (applications.length > 0 && isLoanOfferMissing) {
      if (applicationDetails?.quoteId) {
        dispatch(fetchLoanOffer(applicationDetails.quoteId.toLowerCase()));
      }
    }
  }, [applications, applicationDetails, currentLoanId, dispatch]);

  React.useEffect(() => {
    if (
      loanOfferState.status === FetchStatus.SUCCESS &&
      loanOfferState.loanOffers.length > 0
    ) {
      const token = loanOfferState.loanOffers
        .slice()
        .reverse()
        .find((offer) => `${offer.LoanId}` === currentLoanId)?.LoginToken;
      if (token && !continueToken) {
        dispatch(applicationGetContinueTokenAction(token));
      }
    }
  }, [loanOfferState, dispatch]);

  React.useEffect(() => {
    if (typeof wisrApplicationId === "string" && getUser()) {
      dispatch(fetchLoanApplication(wisrApplicationId));
    } else if (applicationIdFromQuoteId) {
      dispatch(fetchLoanApplication(applicationIdFromQuoteId));
    }
  }, [applicationIdFromQuoteId, dispatch, wisrApplicationId]);

  React.useEffect(() => {
    if (loanApplicationState.loanApplication) {
      sendLoanApplicationAmplitudeEvent({
        eventName: ApplicationEvents.VIEWED_APPLICATION_FORM,
        loanId: loanApplicationState.loanApplication.loanId,
        loanPurpose: getPurposeDescriptionByKey(
          loanApplicationState.loanApplication.loanPurpose,
          loanPurpose
        ),
        status: LoanStatus.APPLICATION_STARTED,
        appFormVersion: "App_Form_v3",
      });
    }
  }, [!!loanApplicationState.loanApplication]);

  React.useEffect(() => {
    if (
      typeof wisrApplicationId === "string" &&
      typeof email === "string" &&
      typeof firstName === "string"
    ) {
      dispatch(fetchApplicationHeader({ wisrApplicationId, email, firstName }));
    }
  }, [dispatch, email, firstName, wisrApplicationId]);

  React.useEffect(() => {
    if (loanParametersStatus === FetchStatus.IDLE) {
      dispatch(fetchLoanParameters());
    }
  }, [dispatch, loanParametersStatus]);

  React.useEffect(() => {
    /* istanbul ignore else */
    if (getUser() && !isNotEmpty(wisrProfile)) {
      dispatch(wisrProfileGetAction());
    } else if (wisrProfile) {
      quoteAmplitudeHelper.setUser(wisrProfile.wisrCustomerId);
    }
  }, [dispatch, wisrProfile]);

  if (!isBrowser()) {
    return null;
  }

  if (!getUser() && loginPage) {
    return <ApplicationLogin />;
  }

  if (!getUser() && createAccountPage) {
    return (
      <ApplicationLayout layoutType="multi-page-form" location={location}>
        <style jsx>{style}</style>
        <div className="flex-wrapper">
          <ApplicationCreateAccount />
        </div>
      </ApplicationLayout>
    );
  }

  if (!getUser() && isStartApplicationPage) {
    return (
      <ApplicationLayout layoutType="multi-page-form" location={location}>
        <style jsx>{style}</style>
        <StartApplication firstNameParam={firstName} />
      </ApplicationLayout>
    );
  }

  if (submittedPage) {
    return <ApplicationSubmitted />;
  }

  if (partnerReferralPage) {
    return (
      <ApplicationLayout
        layoutType={drivaNewDesignEnabled ? "offer-page-navy" : "offer-page"}
        location={location}
      >
        <style jsx>{style}</style>
        <PartnerReferralScreen drivaNewDesignEnabled={drivaNewDesignEnabled} />
      </ApplicationLayout>
    );
  }

  return (
    <ApplicationLayout layoutType="multi-page-form" location={location}>
      <style jsx>{style}</style>
      {!loanApplicationState.loanApplication ? (
        <></>
      ) : (
        <ApplicationInnerFormComponent
          initialFormData={loanApplicationState.loanApplication}
          setFormErrors={setFormErrors}
          applicationPostStatus={loanApplicationState.postStatus}
        />
      )}
      <Loader
        type="complex"
        loading={
          loanApplicationState.getStatus !== FetchStatus.SUCCESS &&
          !isStartApplicationPage
        }
        loadingMessage="Loading your application.."
      />
      <Modal
        titleText={errorModal}
        open={!!errorModal}
        onClose={
          /* istanbul ignore next */ () => {
            setErrorModal("");
            dispatch(clearLoanApplicationErrors());
          }
        }
      >
        {
          /* istanbul ignore next */ isFormError ? (
            <>
              <ul>
                {formErrors.map((error, i) => {
                  return <li key={i}>{error}</li>;
                })}
              </ul>
              <p>
                If you&apos;re still experiencing issues, refresh the page and
                try again.
              </p>
            </>
          ) : (
            <>
              <ul>
                {submissionErrors.map((error, i) => {
                  return <li key={i}>{error.errorMessage}</li>;
                })}
              </ul>
              <p>
                If this issue persists, please{" "}
                <a
                  href={`${BRAND_WEBSITE}/contact-us`}
                  target="_blank"
                  rel="noopener nofollow"
                >
                  contact us
                </a>
                .
              </p>
            </>
          )
        }
        <div className="modal-button-container">
          {
            /* istanbul ignore next */ isFormError && (
              <Button theme="navy" fullWidth={true} button="secondary">
                <button type="button" onClick={() => window.location.reload()}>
                  Refresh
                </button>
              </Button>
            )
          }
          <Button theme="navy" fullWidth={true}>
            <button
              type="button"
              onClick={() => {
                setErrorModal("");
                dispatch(clearLoanApplicationErrors());
              }}
            >
              Close
            </button>
          </Button>
        </div>
      </Modal>
    </ApplicationLayout>
  );
};

export const ApplicationInnerFormComponent: React.FunctionComponent<{
  initialFormData: Application;
  setFormErrors: (arg0: string[]) => void;
  applicationPostStatus: string;
}> = ({ initialFormData, setFormErrors, applicationPostStatus }) => {
  const {
    fieldAdapter,
    setValue,
    submitAdapter,
    formData,
    formState,
    resetForm,
  } = useForm(() => {
    return initialFormData;
  });

  React.useEffect(() => {
    if (applicationPostStatus === FetchStatus.IDLE) {
      resetForm();
    }
  }, [initialFormData]);

  return (
    <MultiPageApplicationForm
      fieldAdapter={fieldAdapter}
      formData={formData}
      setValue={setValue}
      formState={formState}
      submitAdapter={submitAdapter}
      setFormErrors={setFormErrors}
    />
  );
};
