import { RouteComponentProps } from "@reach/router";
import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { Application } from "../../../application";
import { useIsEditing } from "../../hooks/use-is-editing";
import style from "../../multi-page-application-form.scss";

interface PropTypes extends RouteComponentProps {
  setValue: UseFormTools<Application>["setValue"];
}

export const InvestmentProperty: React.FC<PropTypes> = ({
  setValue,
  location,
}) => {
  const [fieldValue, setFieldValue] = React.useState("");
  const isEditing = useIsEditing(location);

  React.useEffect(() => {
    if (fieldValue !== "") {
      setValue("ownsInvestmentProperty", fieldValue);
      setFieldValue("");
      if (fieldValue === "Yes") {
        navigate("/application/investment-mortgaged");
      } else {
        if (isEditing) {
          navigate("/application/review-your-details");
        } else {
          navigate("/application/employment-and-income");
        }
      }
    }
  }, [fieldValue, setValue, isEditing]);

  return (
    <div className="multi-page-application">
      <style jsx>{style}</style>
      <div className="form-contents">
        <div className="heading-wrapper">
          <h2 className="heading">Do you own an investment property?</h2>
        </div>
      </div>

      <Field
        type="radio-list"
        label=""
        options={["Yes", "No"]}
        onChange={(e) => {
          setFieldValue(e.currentTarget.value);
        }}
      />
    </div>
  );
};
