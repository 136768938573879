import { isBrowser, UseFormTools } from "@wisr/common";
import { Icon } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { Application } from "../../../../application";
import { ReviewField } from "../review-fields/review-fields";
import { redirect } from "../../../../../shared/browser.utils";
import { LOAN_OFFER_PAGE } from "../../../../../shared/url.consts";
import { useGetRateEstimateState } from "../../../../../redux/rate-estimate/rate-estimate.hooks";

interface GetEditLinkProps {
  data: ReviewField;
  sectionKey: string;
  formData: UseFormTools<Application>["formData"];
}

export const EditLink = ({ data, sectionKey, formData }: GetEditLinkProps) => {
  const quoteId = useGetRateEstimateState().rateEstimateResponse?.quoteId;

  if (!data.editable || !data.editPath) {
    return null;
  }

  const handleEditLinkClick = () => {
    const touchedSections =
      isBrowser() && window.sessionStorage.getItem("touchedSections");

    const handleNavigate = () => {
      if (data.editPath === "estimate") {
        if (quoteId) {
          redirect(
            `${LOAN_OFFER_PAGE}?qid=${quoteId}&editing=true&term=${formData.loanTerm}`
          );
        }
      } else {
        navigate(`/application/${data.editPath}?editing=true`);
      }
    };

    if (touchedSections) {
      const touchedSectionsArr = JSON.parse(touchedSections);
      const touchedSectionsSet = new Set([...touchedSectionsArr, sectionKey]);
      window.sessionStorage.setItem(
        "touchedSections",
        JSON.stringify(Array.from(touchedSectionsSet))
      );
      handleNavigate();
    } else {
      isBrowser() &&
        window.sessionStorage.setItem(
          "touchedSections",
          JSON.stringify([sectionKey])
        );
      handleNavigate();
    }
  };

  return (
    <div onClick={handleEditLinkClick} className="update-field-icon">
      <Icon name="action-edit-outline" size="sm" legacy={true} />
    </div>
  );
};
