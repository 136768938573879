import { UseFormTools } from "@wisr/common";
import { useDispatch, useSelector } from "react-redux";

import { AppStore } from "../store";
import { Application } from "./application";
import { applicationSaveAction } from "./application-form.actions";
import { ApplicationFormState } from "./application-form.reducer";

export const useApplicationForm = () => {
  return useSelector<AppStore, ApplicationFormState>(
    (store) => store.applicationFormState
  );
};

export const useSaveProgress = (
  formData: UseFormTools<Application>["formData"]
) => {
  const dispatch = useDispatch();
  const saveProgress = () => dispatch(applicationSaveAction(formData));
  return saveProgress;
};
