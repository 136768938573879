import { Icon } from "@wisr/react-ui";
import React from "react";

export const partnerReferralTitleCopy = (
  <>
    <span>Sorry, we&apos;re unable to offer you a Wisr loan.</span>
    <span>But we can refer you to our trusted partner!</span>
  </>
);

export const partnerReferralBodyCopy = (
  <>
    <p>
      Based on the information you&apos;ve provided, we&apos;re unable to offer
      you a Wisr loan but we&apos;d still like to help.
    </p>
    <p>
      We&apos;ve teamed up with Driva, an award-winning Australian loan broker,
      who can help you find an alternative.
    </p>
    <p>
      Driva will check your details against the 30+ lenders on their platform to
      help find a match that works for you. They&apos;ll do this safely and
      securely, with no impact to your credit score.
    </p>
    <p>
      <strong>Ready to check out your loan options with Driva?</strong>
    </p>
  </>
);

export const partnerReferralBodyCopyAlternate = (
  <>
    <p>
      We understand this isn&apos;t the outcome you were hoping for. Wisr takes
      responsible lending seriously and unfortunately you do not meet our
      lending requirements. The good news is that we can put you in touch with
      our friends at Driva.
    </p>
    <p>
      <strong>
        Driva is an award-winning Australian loan broker who talks to over 30
        different lenders and can help you get the loan you need.
      </strong>
    </p>
  </>
);

export const partnerReferralListCopy = (
  <>
    <div className="list-item">
      <Icon name="status-tick" size="md" legacy={false} />
      <h3>More than 30+ lenders</h3>
      <p>Find a loan that will fit your circumstances</p>
    </div>
    <div className="list-item">
      <Icon name="status-tick" size="md" legacy={false} />
      <h3>Safe & secure</h3>
      <p>With no impact to your credit score</p>
    </div>
    <div className="list-item">
      <Icon name="status-tick" size="md" legacy={false} />
      <h3>Personalised rate in 60 seconds</h3>
      <p>Easy process from start to finish</p>
    </div>
  </>
);

export const LOAN_REFERRAL_PRODUCT = "Loan Application";
