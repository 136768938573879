import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Application } from "../../../application";
import { useSaveLoanApplicationProgress } from "../../../../redux/loan-application/loan-application.hooks";
import { useIsEditing } from "../../hooks/use-is-editing";
import { navigate } from "gatsby";
import React from "react";
import style from "../../multi-page-application-form.scss";
import { sendAppFormViewedEvent } from "../../multi-page-application-form.utils";
import { AppFormPages } from "../../../../shared/analytics/analytics.consts";
import requirementsStyle from "./requirements.scss";
import { HelpCallout } from "../../help-callout/help-callout";
import { Button, Field } from "@wisr/react-ui";
import { InfoModal } from "../../info-modal/info-modal";
import {
  requirementsFieldPlaceholder,
  requirementsModalDescription,
  requirementsModalHeading,
} from "./requirements.consts";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  formState: UseFormTools<Application>["formState"];
}

export const Requirements: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  formState,
  location,
}) => {
  const saveProgress = useSaveLoanApplicationProgress(formData);
  const [infoModal, setInfoModal] = React.useState(false);
  const isEditing = useIsEditing(location);

  const isDisabled =
    !formData.requirementsAndObjectives ||
    formState.requirementsAndObjectives.errors.length > 0;

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.REQUIREMENTS);
  }, []);

  const nextPage = () => {
    saveProgress();
    if (isEditing) {
      navigate("/application/review-your-details");
    }
    navigate("/application/dependants");
  };

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{requirementsStyle}</style>

        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">
              Firstly, please share details about why you are applying for a
              loan.
            </h2>
          </div>
          <HelpCallout
            text={requirementsModalHeading}
            setInfoModal={setInfoModal}
            padding="md"
          />
          <div className="input-wrapper">
            <Field
              type="textarea"
              rows={5}
              label="Loan requirements and objectives"
              placeholder={requirementsFieldPlaceholder}
              maxLength={512}
              {...fieldAdapter({
                name: "requirementsAndObjectives",
                required: "Requirements and objectives is required",
              })}
            />
          </div>
        </div>

        <div className="button-wrapper">
          <Button
            button="primary"
            theme="navy"
            size="large"
            fullWidth={true}
            disabled={isDisabled}
          >
            <button
              className="next-page-button"
              onClick={() => nextPage()}
              disabled={isDisabled}
            >
              {isEditing ? "back to review" : "next"}
            </button>
          </Button>
        </div>
      </div>
      <InfoModal
        infoModal={infoModal}
        setInfoModal={setInfoModal}
        heading={requirementsModalHeading}
        description={requirementsModalDescription}
      />
    </>
  );
};
