// We are ignoring any in this file because it contains our fake jquery mapper
/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */
import Axios from "axios";
import React from "react";

import { BRAND_WEBSITE } from "../shared/url.consts";
import style from "./credit-sense-iframe.component.scss";

export enum CreditSenseResult {
  Complete = "100",
  Success = "99",
  Initialise = "1",
  BankLoginFailure = "-2",
  BankLoginTooManyAttempts = "-3",
  BankMissing = "-4",
  BankPasswordFailure = "-5",
  UnableToReachCreditSense = "-99",
}

function isCreditSenseResult(res: unknown): res is CreditSenseResult {
  return !!Object.values(CreditSenseResult).find((v) => v === res);
}

interface Props {
  client: string;
  allowDebugBanks: boolean;
  loanId: string;
  onResult: (result: CreditSenseResult) => void;
  dataKey?: string;
}

export const CreditSenseIframeComponent: React.FunctionComponent<Props> = ({
  loanId,
  onResult,
  allowDebugBanks,
  client,
  dataKey,
}) => {
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    const scriptId = `credit-sense-script-${dataKey}`;
    const customCss = `${BRAND_WEBSITE}/apply/credit-sense-override.css`.split(
      "//"
    )[1];

    let script = document.getElementById(scriptId) as HTMLScriptElement | null;

    if (!script) {
      script = document.createElement("script");
      script.src =
        "https://6dadc58e31982fd9f0be-d4a1ccb0c1936ef2a5b7f304db75b8a4.ssl.cf4.rackcdn.com/CS-Integrated-Iframe-v1.min.js";
      script.id = scriptId;

      const $ = setupFakeJQuery();
      document.body.appendChild(script);
      script.onload = () => {
        $.CreditSense.Iframe({
          client,
          elementSelector: "#creditsenseframe",
          newsession: true, // Force new session each time
          params: {
            appRef: loanId,
            uniqueAppRef: true,
            centrelink: false, // DEFAULT = TRUE
            debugBanks: allowDebugBanks.toString(),
            bankOverwrite: "", // Bank ID, not provided = ask.
            alreadyAgreedTerms: false, // Default = False = Ask.
            bgcolour: "FFFFF", //'d7ecdb',
            enableDynamicHeight: true,
            cssOverride: customCss,
          },
          debug: false,
          callback: (res: string) => {
            if (isCreditSenseResult(res)) {
              return onResult(res);
            }

            const resizeHeightLookup = /resizeHeight=(\d*)/gi.exec(res);
            if (resizeHeightLookup && resizeHeightLookup.length > 0) {
              return setHeight(+resizeHeightLookup[1]);
            }
          },
        });
      };
    }
  }, [allowDebugBanks, client, loanId, onResult, dataKey]);

  return (
    <div>
      <style jsx>{style}</style>
      <iframe
        id="creditsenseframe"
        src="about:blank"
        style={{ height: `${height}px` }}
      >
        iframes are not supported in this browser
      </iframe>
    </div>
  );
};

function setupFakeJQuery(): any {
  function fakeJQuery(selector: string) {
    const element = document.querySelector(selector);

    return {
      ...element,
      attr: (att: string, value: string) => {
        element?.setAttribute(att, value);
        return {};
      },
    };
  }
  fakeJQuery.getJSON = (path: string) => {
    return {
      done: (callback: any) => {
        const axiosInstance = Axios.create();
        axiosInstance.get(path).then((response) => {
          callback(response.data);
        });
      },
    };
  };

  fakeJQuery.isNumeric = (value: any) => {
    return !isNaN(parseFloat(value));
  };
  fakeJQuery.extend = (base: any, defaults: any, options: any) => {
    return {
      ...base,
      ...defaults,
      ...options,
    };
  };
  fakeJQuery.each = (data: any, cb: any) =>
    Object.keys(data).forEach((key) => cb(key, data[key]));

  fakeJQuery.fn = fakeJQuery.prototype;

  (window as any).jQuery = fakeJQuery;
  (window as any).gfn = { log: () => undefined };
  return fakeJQuery;
}

/* 
For Future reference, this is the URL that is generated from the credit sense library
https://creditsense.com.au/apply/
    DM02/
        ?method=iframe
        &pageUuid=33e9d585-8ef4-431f-9566-61bbd24e039c
        &comtype=PM
        &appRef=1028273
        &uniqueAppRef=true
        &centrelink=false
        &debugBanks=true
        &alreadyAgreedTerms=false
        &bgcolour=FFFFF
        &enableDynamicHeight=true
        &PHPSESS=4psf2ultts6g6skt6r3b6kar82&
*/
