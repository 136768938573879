import React from "react";

export const EMPTY_LOAN_DATA = {
  firstName: "",
  loanId: "",
  loanPurposeText: "",
  loanTerm: "",
  loanAmount: "",
};

export const startApplicationStatusMessage: React.ReactElement = (
  <>
    <p>
      <strong>Have these documents ready to go</strong>
    </p>
    <p>
      <strong>Two forms of ID:</strong> Driver licence, Medicare card or
      passport
    </p>
    <p>
      <strong>Proof of income:</strong> Payslips, tax returns, investments and
      assets
    </p>
    <p>
      <strong>Online banking details:</strong> To access your bank statements
    </p>
  </>
);
