import React from "react";
import { AppFormPages } from "../../../shared/analytics/analytics.consts";

import { InfoModal } from "../info-modal/info-modal";
import { sendAppFormViewedEvent } from "../multi-page-application-form.utils";
import style from "./nsr-modal.scss";

interface PropTypes {
  nsrModal: boolean;
  setNsrModal: (arg0: boolean) => void;
}

export const NsrModal: React.FC<PropTypes> = ({ nsrModal, setNsrModal }) => {
  const heading = "Review your application";
  const description = (
    <div className="nsr-description">
      <style jsx>{style}</style>
      <p>
        Wisr is a responsible lender and based on what you&apos;ve told us, you
        may find it difficult to repay the loan amount you&apos;ve requested.
      </p>
      <p>
        <strong>Your credit score has not been impacted.</strong>
      </p>
      <p>
        <strong>What can you do?</strong>
      </p>
      <p>
        Check your application for errors. If you spot something wrong you can
        edit and resubmit your application.
      </p>
      <p>We recommend double-checking these sections:</p>
      <ul>
        <li>Your living situation</li>
        <li>Your income </li>
        <li>Your expenses</li>
        <li>Your debts</li>
      </ul>
      <p>Team Wisr</p>
    </div>
  );

  React.useEffect(() => {
    if (nsrModal) {
      sendAppFormViewedEvent(AppFormPages.BELOW_NSR);
    }
  }, [nsrModal]);

  return (
    <InfoModal
      infoModal={nsrModal}
      setInfoModal={setNsrModal}
      heading={heading}
      description={description}
      buttonText="Review application"
    />
  );
};
