import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Button, Field, Fieldset } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { Application } from "../../../application";
import { validPhoneNumberRegex } from "../../../application-form.constants";
import { HelpCallout } from "../../help-callout/help-callout";
import { useIsEditing } from "../../hooks/use-is-editing";
import { InfoModal } from "../../info-modal/info-modal";
import style from "../../multi-page-application-form.scss";
import { sendAppFormViewedEvent } from "../../multi-page-application-form.utils";
import {
  employmentIndustryOptions,
  employmentStatusOptions,
  occupationOptions,
} from "./employment-and-income.constants";
import employmentAndIncomeStyle from "./employment-and-income.scss";
import { AppFormPages } from "../../../../shared/analytics/analytics.consts";
import { AutoFillFix } from "../../../../shared/application/auto-fill-fix/auto-fill-fix.component";
import { useSaveLoanApplicationProgress } from "../../../../redux/loan-application/loan-application.hooks";
import { isValidLengthWithoutDecimals } from "../../../../redux/loan-application/loan-application.utils";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  formState: UseFormTools<Application>["formState"];
}

export const EmploymentAndIncome = ({
  fieldAdapter,
  formData,
  formState,
  location,
}: PropTypes) => {
  const saveProgress = useSaveLoanApplicationProgress(formData);
  const [infoModal, setInfoModal] = React.useState(false);
  const modalHeading = "Why do we ask this?";
  const modalDescription =
    "As part of our assessment process Wisr may contact your employer to verify your employment status and income.";
  const isEditing = useIsEditing(location);
  const isLessThanSixMonths =
    parseInt(formData.yearsAtEmployer) === 0 &&
    parseInt(formData.monthsAtEmployer) < 6;

  const nextPage = () => {
    saveProgress();
    if (isEditing) {
      if (isLessThanSixMonths) {
        navigate("/application/previous-employment-and-income?editing=true");
      } else {
        navigate("/application/review-your-details");
      }
    } else {
      if (isLessThanSixMonths) {
        navigate("/application/previous-employment-and-income");
      } else if (
        formData.maritalStatus === "married" ||
        formData.maritalStatus === "de-facto"
      ) {
        navigate("/application/partners-income");
      } else {
        navigate("/application/other-income");
      }
    }
  };

  const validateContactNumber = (value: string) => {
    return (
      !value ||
      validPhoneNumberRegex.test(value) ||
      "Employer contact number must have 10 digits, this means landlines must include an area code"
    );
  };

  const isDisabled =
    !formData.employmentStatus ||
    !formData.yearsAtEmployer ||
    !formData.monthsAtEmployer ||
    !formData.employmentIndustry ||
    !formData.occupation ||
    !formData.employerName ||
    !formData.incomeAmount ||
    formState.incomeAmount.errors.length > 0 ||
    !validPhoneNumberRegex.test(formData.employmentContactNumber);

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.EMPLOYMENT);
  }, []);

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{employmentAndIncomeStyle}</style>

        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">Your primary employment & income</h2>
          </div>

          <HelpCallout text={modalHeading} setInfoModal={setInfoModal} />

          <div className="input-wrapper">
            <Fieldset>
              <AutoFillFix
                section="current-employer"
                safeTypes={["name", "tel-national"]}
              />
              <Field
                type="select"
                label="Employment status"
                options={employmentStatusOptions}
                placeholder="Select..."
                {...fieldAdapter({
                  name: "employmentStatus",
                  required: "Employment status is required",
                })}
              ></Field>

              <Field
                layout="pair"
                type="number"
                label="Years at employer"
                {...fieldAdapter({
                  name: "yearsAtEmployer",
                  validate: (value, form) =>
                    form.monthsAtEmployer !== "" ||
                    form.yearsAtEmployer !== "" ||
                    "Years at employer is required",
                  min: [0],
                  max: [99],
                })}
              ></Field>

              <Field
                layout="pair"
                type="number"
                label="Months at employer"
                {...fieldAdapter({
                  name: "monthsAtEmployer",
                  validate: (value, form) =>
                    form.monthsAtEmployer !== "" ||
                    form.yearsAtEmployer !== "" ||
                    "Months at employer is required",
                  min: [0, "Months at employer is required"],
                  max: [11, "Months at employer must be 11 or less"],
                })}
              ></Field>
              <Field
                layout="single"
                label="Employer industry"
                type="select"
                options={employmentIndustryOptions}
                placeholder="Select..."
                {...fieldAdapter({
                  name: "employmentIndustry",
                  required: "Current employer industry is required",
                })}
              ></Field>
              <Field
                layout="single"
                label="Occupation"
                type="select"
                options={occupationOptions}
                placeholder="Select..."
                {...fieldAdapter({
                  name: "occupation",
                  required: "Current occupation is required",
                })}
              ></Field>
              <Field
                layout="single"
                label="Employer name"
                autoComplete="section-current-employer name"
                type="text"
                {...fieldAdapter({
                  name: "employerName",
                  required: "Current employer name is required",
                })}
              ></Field>
              <Field
                layout="single"
                label="Employer contact number"
                autoComplete="section-current-employer tel-national"
                type="mobile"
                {...fieldAdapter({
                  name: "employmentContactNumber",
                  validate: validateContactNumber,
                  required: "Current employer contact number is required",
                })}
              ></Field>
              <Field
                layout="single"
                type="currency"
                label="Annual income (before tax)"
                hasCommas={true}
                {...fieldAdapter({
                  name: "incomeAmount",
                  required: "Annual income amount is required",
                  min: [0],
                  validate: (value) =>
                    isValidLengthWithoutDecimals(value, 10) ||
                    "Annual income amount cannot be more than 10 digits (including 2 decimals)",
                })}
              ></Field>
            </Fieldset>
          </div>

          <div className="button-wrapper">
            <Button
              button="primary"
              theme="navy"
              size="large"
              fullWidth={true}
              disabled={isDisabled}
            >
              <button
                className="next-page-button"
                onClick={() => nextPage()}
                disabled={isDisabled}
              >
                {isEditing && !isLessThanSixMonths ? "back to review" : "next"}
              </button>
            </Button>
          </div>
        </div>
      </div>
      <InfoModal
        infoModal={infoModal}
        setInfoModal={setInfoModal}
        heading={modalHeading}
        description={modalDescription}
      />
    </>
  );
};
