import { useSelector } from "react-redux";
import { AppStore } from "../../store";
import { selectLoanApplicationStatusState } from "./loan-application-status.slice";
import { LoanApplicationStatusState } from "../../types/loan-application-status";

export const useGetLoanApplicationStatusState = () => {
  return useSelector<AppStore, LoanApplicationStatusState>(
    selectLoanApplicationStatusState
  );
};
