import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Button, Field, Icon, StatusCallout } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { Application } from "../../../../application";
import { useIsEditing } from "../../../hooks/use-is-editing";
import style from "../../../multi-page-application-form.scss";
import {
  CREDIT_CARD_VALUE,
  MORTGAGE_VALUE,
  PERSONAL_LOAN_VALUE,
  debtTypeOptions,
} from "./debt-list.constants";
import debtListStyle from "./debt-list.scss";
import {
  addDebtListItem,
  CreditCardDebtQuestions,
  deleteDebtListItem,
  getDebtListCalloutMessage,
  getDebtListInitialValues,
  isDebtListButtonDisabled,
  MortgageDebtInputs,
  PersonLoanDebtInputs,
} from "./debt-list.utils";
import { useGetLoanParameters } from "../../../../../loans/parameters/loan-parameters.hooks";
import { useSaveLoanApplicationProgress } from "../../../../../redux/loan-application/loan-application.hooks";
import {
  getIsPurposeSecurableByKey,
  sendAppFormViewedEvent,
} from "../../../multi-page-application-form.utils";
import { AppFormPages } from "../../../../../shared/analytics/analytics.consts";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  setValue: UseFormTools<Application>["setValue"];
  formState: UseFormTools<Application>["formState"];
}

export const DebtList: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  setValue,
  formState,
  location,
}) => {
  const saveProgress = useSaveLoanApplicationProgress(formData);
  const loanParameters = useGetLoanParameters();
  const isPurposeSecurable = getIsPurposeSecurableByKey(
    formData.loanPurpose,
    loanParameters.loanPurpose
  );

  const [debtList, setDebtList] = React.useState(
    getDebtListInitialValues(formData)
  );
  const isEditing = useIsEditing(location);
  const isDisabled = isDebtListButtonDisabled(debtList, formData, formState);
  const calloutMessage = getDebtListCalloutMessage(formData);

  const nextPage = () => {
    saveProgress();
    if (isEditing) {
      navigate("/application/review-your-details");
    } else {
      navigate("/application/your-bank-statements");
    }
  };

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.DEBT_LIST);
  }, []);

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{debtListStyle}</style>

        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">Tell us about your debts</h2>
          </div>
          <p>Help us process your loan faster by declaring all your debts.</p>
          {calloutMessage && (
            <div className="callout">
              <div className="status-callout-wrapper">
                <StatusCallout
                  type="coaching"
                  theme="outline"
                  message={calloutMessage}
                />
              </div>
            </div>
          )}
          <div className="input-wrapper">
            {debtList.map((debtItem, index) => {
              const nextListItem = debtList[index + 1];
              const hasAddDebt = nextListItem && nextListItem.active === false;
              const isCreditCard =
                formData[debtItem.type] === CREDIT_CARD_VALUE;
              const debtTypeLabel = isCreditCard
                ? "Credit card"
                : debtTypeOptions.find(
                    (debtType) => debtType.value === formData[debtItem.type]
                  )?.label ?? "Debt type";

              return (
                <div key={`${debtItem.id}${debtItem.active}`}>
                  {formData[debtItem.type] ? (
                    <div className="debt-type-title">
                      <h3>{debtTypeLabel}</h3>
                      <div
                        className="delete-debt-item"
                        onClick={() => {
                          deleteDebtListItem(
                            debtItem.id,
                            debtList,
                            setDebtList,
                            setValue,
                            formData
                          );
                        }}
                      >
                        <Icon
                          name="action-trash-outline"
                          size="sm"
                          legacy={false}
                        />
                      </div>
                    </div>
                  ) : (
                    <Field
                      type="select"
                      label="Debt type"
                      options={debtTypeOptions}
                      placeholder="Select"
                      {...fieldAdapter({
                        name: debtItem.type,
                        condition: debtItem.active,
                      })}
                    />
                  )}

                  {formData[debtItem.type] && (
                    <>
                      <div>
                        <Field
                          type="input"
                          label="Finance company name"
                          {...fieldAdapter({
                            name: debtItem.financeCompany,
                            required: "Finance company name is required",
                            min: [1],
                            condition: debtItem.active,
                          })}
                        />
                        {formData[debtItem.type] === CREDIT_CARD_VALUE && (
                          <CreditCardDebtQuestions
                            fieldAdapter={fieldAdapter}
                            setValue={setValue}
                            debtItem={debtItem}
                            formData={formData}
                            debtList={debtList}
                            setDebtList={setDebtList}
                            isPurposeSecurable={isPurposeSecurable}
                          />
                        )}
                        {formData[debtItem.type] === MORTGAGE_VALUE && (
                          <MortgageDebtInputs
                            fieldAdapter={fieldAdapter}
                            setValue={setValue}
                            debtItem={debtItem}
                            formData={formData}
                            debtList={debtList}
                            setDebtList={setDebtList}
                          />
                        )}
                        {formData[debtItem.type] === PERSONAL_LOAN_VALUE && (
                          <PersonLoanDebtInputs
                            fieldAdapter={fieldAdapter}
                            setValue={setValue}
                            debtItem={debtItem}
                            formData={formData}
                            debtList={debtList}
                            setDebtList={setDebtList}
                            isPurposeSecurable={isPurposeSecurable}
                          />
                        )}
                      </div>
                      <hr />
                      {hasAddDebt && (
                        <div
                          className="add-debt"
                          onClick={() => {
                            addDebtListItem(index + 1, debtList, setDebtList);
                          }}
                        >
                          <Icon
                            name="action-add-circle"
                            size="sm"
                            legacy={false}
                          />
                          <p>Add another debt</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="button-wrapper">
          <Button
            button="primary"
            theme="navy"
            size="large"
            fullWidth={true}
            disabled={isDisabled}
          >
            <button
              className="next-page-button"
              onClick={() => nextPage()}
              disabled={isDisabled}
            >
              {isEditing ? "back to review" : "next"}
            </button>
          </Button>
        </div>
      </div>
    </>
  );
};
