import React from "react";
import style from "../multi-page-application-form/multi-page-application-form.scss";
import createAccountStyle from "./create-account.scss";
import createAccountImage from "../../assets/images/application/create-account-illustration.svg";
import { capitalise, isBrowser, useForm } from "@wisr/common";
import { Field, Button, Modal, Icon } from "@wisr/react-ui";
import { HelpCallout } from "../multi-page-application-form/help-callout/help-callout";
import { InfoModal } from "../multi-page-application-form/info-modal/info-modal";
import { ApplicationCreateAccountInitial } from "./create-account.consts";
import { useGetApplicationHeaderState } from "../../redux/application-header/application-header.hooks";
import { useDispatch } from "react-redux";
import { fetchApplicationLogin } from "../login/application-login.thunk";
import { useGetApplicationLoginState } from "../login/application-login.hooks";
import { navigate } from "gatsby";
import { storeUser } from "../../auth/auth.utils";
import { decodeToken } from "../login/application-login.utils";
import { isValidPassword } from "./create-account.utils";
import {
  requirementsFieldPlaceholder,
  requirementsModalDescription,
  requirementsModalHeading,
} from "../multi-page-application-form/form-pages/requirements/requirements.consts";
import { AppFormPages } from "../../shared/analytics/analytics.consts";
import { sendAppFormViewedEvent } from "../multi-page-application-form/multi-page-application-form.utils";

export const ApplicationCreateAccount: React.FC = () => {
  const [infoModal, setInfoModal] = React.useState(false);
  const [passwordModal, setPasswordModal] = React.useState(false);
  const dispatch = useDispatch();
  const applicationDetails = useGetApplicationHeaderState().applicationHeader;
  const applicationLoginResponse = useGetApplicationLoginState().loginResponse;
  const loginSuccess = applicationLoginResponse?.failed === false;
  const accessToken = applicationLoginResponse?.tokenDetails?.accessToken;
  const refreshToken = applicationLoginResponse?.tokenDetails?.refreshToken;

  const passwordModalHeading = `Hey ${
    applicationDetails?.firstName
      ? `${capitalise(applicationDetails.firstName)}`
      : "there"
  }, let's save your progress`;

  const { fieldAdapter, submitAdapter, formData, formState } = useForm(
    ApplicationCreateAccountInitial
  );

  const isDisabled =
    !formData.requirementsAndObjectives ||
    formState.requirementsAndObjectives.errors.length > 0;
  const validPassword = isValidPassword(formData.password);

  const onSubmit = submitAdapter((result: boolean, submittedFormData) => {
    if (result && applicationDetails?.email) {
      dispatch(
        fetchApplicationLogin({
          email: applicationDetails.email,
          password: submittedFormData.password,
        })
      );
    }
    return Promise.resolve();
  });

  React.useEffect(() => {
    if (loginSuccess && accessToken && refreshToken) {
      const tokenObject = decodeToken(accessToken);

      if (isBrowser()) {
        window.sessionStorage.setItem(
          "requirementsAndObjectives",
          formData.requirementsAndObjectives
        );
      }

      storeUser(
        refreshToken,
        tokenObject.auth_time,
        tokenObject.sub,
        "Bearer",
        accessToken
      );
      setPasswordModal(false);
      navigate("/login");
    }
  }, [
    accessToken,
    formData.requirementsAndObjectives,
    loginSuccess,
    refreshToken,
  ]);

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.REQUIREMENTS);
  }, []);

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{createAccountStyle}</style>
        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">
              Firstly, please share details about why you are applying for a
              loan.
            </h2>
          </div>
          <HelpCallout
            text={requirementsModalHeading}
            setInfoModal={setInfoModal}
            padding="md"
          />
          <div className="input-wrapper">
            <Field
              type="textarea"
              rows={5}
              placeholder={requirementsFieldPlaceholder}
              label="Loan requirements and objectives"
              maxLength={512}
              {...fieldAdapter({
                name: "requirementsAndObjectives",
                required: "Requirements and objectives is required",
              })}
            />
          </div>
        </div>

        <div className="button-wrapper">
          <Button
            button="primary"
            theme="navy"
            size="large"
            fullWidth={true}
            disabled={isDisabled}
          >
            <button
              className="next-page-button"
              onClick={() => setPasswordModal(true)}
              disabled={isDisabled}
            >
              next
            </button>
          </Button>
        </div>
      </div>
      <InfoModal
        infoModal={infoModal}
        setInfoModal={setInfoModal}
        heading={requirementsModalHeading}
        description={requirementsModalDescription}
      />
      <Modal
        open={passwordModal}
        onClose={/* istanbul ignore next */ () => null}
        selfClose={false}
      >
        <div className="password-modal">
          <style jsx>{createAccountStyle}</style>
          <img src={createAccountImage} className="create-account-image" />
          <h3>{passwordModalHeading}</h3>
          <div className="password-description">
            <p>Set a password to:</p>
            <div className="description-list">
              <span>
                <Icon name="status-tick" size="sm" legacy={false} />
                Securely access your application anytime
              </span>
              <span>
                {" "}
                <Icon name="status-tick" size="sm" legacy={false} />
                Protect your details
              </span>
              <span>
                {" "}
                <Icon name="status-tick" size="sm" legacy={false} />
                Access loan management tools Wisr App and Dashboard
              </span>
            </div>
          </div>
          <form noValidate className="password-form" onSubmit={onSubmit}>
            <Field
              label="Password"
              type="password"
              placeholder="Your password"
              autoComplete="current-password"
              {...fieldAdapter({
                name: "password",
              })}
            />
            <p
              className={`password-rules ${
                formState.password.dirty ? "dirty" : ""
              }`}
            >
              Must be{" "}
              <span className={`${validPassword.validLength}`}>
                at least 8 characters
              </span>{" "}
              and include a combination of{" "}
              <span className={`${validPassword.hasCapital}`}>uppercase</span>,{" "}
              <span className={`${validPassword.hasLower}`}>lowercase</span>,{" "}
              <span className={`${validPassword.hasNumber}`}>numbers</span> and{" "}
              <span className={`${validPassword.hasSymbol}`}>symbols</span>
            </p>
            <div className="button-wrapper">
              <Button theme="navy" fullWidth>
                <button type="submit">Continue</button>
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
