import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "../../store";
import { LoanApplicationState } from "../../types/loan-application";
import { selectLoanApplicationState } from "./loan-application.slice";
import { saveLoanApplication } from "./loan-application.thunk";
import { UseFormTools } from "@wisr/common";
import { Application } from "../../application/application";
import { mapToLoanApplicationRequest } from "./loan-application.utils";
import { useGetWisrApplicationId } from "../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.hooks";

export const useGetLoanApplicationState = () => {
  return useSelector<AppStore, LoanApplicationState>(
    selectLoanApplicationState
  );
};

export const useSaveLoanApplicationProgress = (
  formData: UseFormTools<Application>["formData"]
) => {
  const dispatch = useDispatch();
  const wisrApplicationId = useGetWisrApplicationId();
  const loanApplication = mapToLoanApplicationRequest(formData);
  return () =>
    dispatch(
      saveLoanApplication({
        wisrApplicationId,
        loanApplication,
      })
    );
};
