import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { isValidDate, UseFormTools } from "@wisr/common";
import { Field, Icon } from "@wisr/react-ui";
import React from "react";
import { stateCardNumberInfographic } from "../../../../../shared/application/drivers-license/card-number-infographic";

import { Application } from "../../../../application";
import { ID_TYPES } from "../../../../application.utils";
import style from "../../../multi-page-application-form.scss";
import { SecurityMsg } from "../../../security-msg/security-msg";
import idDocsStyle from "../id-documents.scss";
import { DocumentSubmit } from "../status-and-submit/document-submit";
import driversLicenceStyle from "./drivers-licence.scss";
import { australianStateOptions } from "../id-documents.consts";
import { validDriverLicenceExpiry } from "../utils/id-documents.utils";
import { licenceNumberValidator } from "../../../../../forms/credit-profile/identification/identification-credit-profile-form.utils";
import { AppFormPages } from "../../../../../shared/analytics/analytics.consts";
import { sendAppFormViewedEvent } from "../../../multi-page-application-form.utils";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  setValue: UseFormTools<Application>["setValue"];
  formState: UseFormTools<Application>["formState"];
}

export const DriversLicence: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  setValue,
  formState,
  location,
}) => {
  const [showCardNumberInfo, setShowCardNumberInfo] = React.useState(false);

  const isDisabled =
    !formData.driverLicenceNumber ||
    !formData.driverLicenceCardNumber ||
    !formData.driversLicenceExpiry ||
    !formData.driversLicenceState ||
    formState.driverLicenceNumber.errors.length > 0 ||
    formState.driverLicenceCardNumber.errors.length > 0 ||
    formState.driversLicenceExpiry.errors.length > 0 ||
    !isValidDate(formData.driversLicenceExpiry) ||
    !validDriverLicenceExpiry(formData.driversLicenceExpiry);

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.DRIVER_LICENCE);
  }, []);

  return (
    <div className="multi-page-application">
      <style jsx>{style}</style>
      <style jsx>{idDocsStyle}</style>
      <style jsx>{driversLicenceStyle}</style>

      <div className="form-contents">
        <div className="heading-wrapper">
          <h2 className="heading">Driver licence details</h2>
        </div>

        <SecurityMsg />

        <div className="input-wrapper">
          <Field
            id={`form_application_input_licence_state`}
            layout="single"
            label="State of issue"
            type="select"
            placeholder="Select an option"
            options={australianStateOptions}
            required
            {...fieldAdapter({
              name: "driversLicenceState",
              required: "Please enter your licence state",
            })}
          />

          <Field
            id={`form_application_input_licence_number`}
            label="Licence number "
            placeholder="- - - - - - - - - -"
            type="text"
            required
            {...fieldAdapter({
              name: "driverLicenceNumber",
              required: "Please enter your drivers licence",
              minLength: [
                4,
                "Licence number must be at least 4 characters long",
              ],
              maxLength: [
                9,
                "Licence number cannot be more than 9 characters long",
              ],
              validate: licenceNumberValidator,
            })}
          />

          <Field
            id={`form_application_input_licence_cardnumber`}
            label="Card number"
            type="text"
            placeholder="- - - - - - - - - -"
            required
            {...fieldAdapter({
              name: "driverLicenceCardNumber",
              required: "Please enter licence card number",
              minLength: [
                6,
                "Licence card number must be at least 6 characters long",
              ],
              maxLength: [
                10,
                "Licence card number cannot be more than 10 characters long",
              ],
              validate: licenceNumberValidator,
            })}
          />

          {formData.driversLicenceState && (
            <div className="card-number-description">
              <div
                className="help-wrapper"
                onClick={() => setShowCardNumberInfo(!showCardNumberInfo)}
              >
                <Icon name="action-help-outline" size="sm" legacy={false} />
                <p>Where do I find my card number?</p>
              </div>

              {stateCardNumberInfographic({ showCardNumberInfo, formData })}
            </div>
          )}

          <Field
            type="date"
            label="Expiry date"
            {...fieldAdapter({
              name: "driversLicenceExpiry",
              required: "Driver licence expiry date is required",
              validate: (value) => {
                if (!isValidDate(value))
                  return "Please enter a valid expiry date in the format DD / MM / YYYY";
                if (!validDriverLicenceExpiry(value))
                  return "Driver licence cannot be expired";
                return true;
              },
            })}
          />
        </div>
      </div>
      <DocumentSubmit
        isDisabled={isDisabled}
        location={location}
        setValue={setValue}
        formData={formData}
        idType={ID_TYPES.DRIVERS_LICENCE}
      />
    </div>
  );
};
