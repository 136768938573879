import React from "react";

import padlock from "../../../assets/images/padlock.svg";
import securityMsgStyle from "./security-msg.scss";

export const SecurityMsg = () => {
  return (
    <>
      <style jsx>{securityMsgStyle}</style>

      <div className="security-msg-wrapper">
        <div className="padloack-wrapper">
          <img src={padlock} alt="padloackIcon" />
        </div>

        <p>
          Wisr uses bank grade security and our platform is 100% encrypted.{" "}
        </p>
      </div>
    </>
  );
};
