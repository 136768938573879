import React from "react";
import { AppFormPages } from "../../../../shared/analytics/analytics.consts";
import { sendAppFormViewedEvent } from "../../multi-page-application-form.utils";
import Lottie from "react-lottie";
import style from "./application-submitted.scss";
import loadingBrain from "./loading-brain.json";
import { TrustPilotWidget } from "../../../../trust-pilot/trust-pilot-widget";
import { Wrapper } from "@wisr/react-ui";
import { useGetLoanApplicationStatusState } from "../../../../redux/loan-application-status/loan-application-status.hooks";
import {
  LoanApplicationStatus,
  assessmentStates,
  notSubmittedStates,
} from "../../../../redux/loan-application-status/loan-application-status.consts";
import { useDispatch } from "react-redux";
import { redirect } from "../../../../shared/browser.utils";
import { fetchLoanApplicationStatus } from "../../../../redux/loan-application-status/loan-application-status.thunk";
import { useGetWisrApplicationId } from "../../../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.hooks";
import { LEGACY_APPLICATION_APP } from "../../../../shared/url.consts";
import { useApplicationForm } from "../../../application-form.hooks";
import { FetchStatus } from "../../../../shared/redux.consts";
import { navigate } from "gatsby";
import { isBrowser } from "@wisr/common";
import { clearLoanApplicationData } from "../../../../redux/loan-application/loan-application.slice";
import { fetchLoanApplication } from "../../../../redux/loan-application/loan-application.thunk";

export const ApplicationSubmitted: React.FC = () => {
  const dispatch = useDispatch();
  const loanApplicationStatusState = useGetLoanApplicationStatusState();
  const wisrApplicationId = useGetWisrApplicationId();
  const { continueToken } = useApplicationForm();
  const continueTokenFallback = isBrowser()
    ? window.sessionStorage.getItem("ContinueToken")
    : null;
  const continueTokenToUse = continueToken || continueTokenFallback;
  const [isAssessment, setIsAssessment] = React.useState(false);
  const NOT_SUBMITTED_DELAY = 10000;
  const ASSESSMENT_DELAY = 5000;
  const PENDING_DELAY = 1000;

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.SUBMISSION_LOADING);
  }, []);

  React.useEffect(() => {
    if (
      loanApplicationStatusState.status === FetchStatus.IDLE &&
      wisrApplicationId
    ) {
      dispatch(clearLoanApplicationData());
      dispatch(fetchLoanApplication(wisrApplicationId));
      dispatch(fetchLoanApplicationStatus(wisrApplicationId));
    }
  }, [dispatch, loanApplicationStatusState.status, wisrApplicationId]);

  React.useEffect(() => {
    if (loanApplicationStatusState.loanApplicationStatus?.status) {
      const fetchApplicationStatus = () =>
        dispatch(fetchLoanApplicationStatus(wisrApplicationId));

      if (
        loanApplicationStatusState.loanApplicationStatus.status ===
        LoanApplicationStatus.PRE_SUBMIT_NSR_FAILED
      ) {
        navigate(
          `/application/review-your-details?status=${LoanApplicationStatus.PRE_SUBMIT_NSR_FAILED}`
        );
      } else if (
        notSubmittedStates.includes(
          loanApplicationStatusState.loanApplicationStatus.status
        )
      ) {
        setTimeout(fetchApplicationStatus, NOT_SUBMITTED_DELAY);
      } else if (
        loanApplicationStatusState.loanApplicationStatus.status ===
        LoanApplicationStatus.PENDING
      ) {
        setTimeout(fetchApplicationStatus, PENDING_DELAY);
      } else if (
        assessmentStates.includes(
          loanApplicationStatusState.loanApplicationStatus.status
        ) &&
        !isAssessment
      ) {
        setIsAssessment(true);
        setTimeout(fetchApplicationStatus, ASSESSMENT_DELAY);
      } else if (
        loanApplicationStatusState.loanApplicationStatus.status ===
        LoanApplicationStatus.DECLINED
      ) {
        if (loanApplicationStatusState.loanApplicationStatus.isDrivaEligible) {
          navigate("/application/partner-referral");
        } else {
          redirect(
            `${LEGACY_APPLICATION_APP}/home/ApplicationCompleted?t=${continueTokenToUse}`
          );
        }
      } else {
        redirect(
          `${LEGACY_APPLICATION_APP}/home/ApplicationCompleted?t=${continueTokenToUse}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    continueToken,
    dispatch,
    loanApplicationStatusState.loanApplicationStatus,
    wisrApplicationId,
  ]);

  return (
    <Wrapper>
      <style jsx>{style}</style>
      <div className="application-submitted flex-column">
        <div className="heading flex-column">
          <Lottie
            width="100px"
            options={{
              animationData: loadingBrain,
              loop: true,
              autoplay: true,

              rendererSettings: {
                preserveAspectRatio: "xMidYMid meet",
              },
            }}
          />
          <div className="info-column">
            <h4>Please wait while we process your application</h4>
            <p>The next step will be to upload your supporting documents.</p>
          </div>
        </div>

        <TrustPilotWidget
          position="center"
          backroundColour="brand-teal-100"
          wisrLogo={true}
        />
      </div>
    </Wrapper>
  );
};
