import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Button, Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { Application } from "../../../../application";
import { useIsEditing } from "../../../hooks/use-is-editing";
import style from "../../../multi-page-application-form.scss";
import { getMortgagedInvestmentProperty } from "../../your-debts/debt-list/debt-list.utils";
import {
  calcSpendingTotal,
  getAdditionalSpendingFields,
  isYourSpendingButtonDisabled,
} from "../utils/your-spending.utils";
import additionalExpensesStyle from "./additional-expenses.scss";
import { useGetLoanParameters } from "../../../../../loans/parameters/loan-parameters.hooks";
import { useSaveLoanApplicationProgress } from "../../../../../redux/loan-application/loan-application.hooks";
import { getIsPurposeSecurableByKey } from "../../../multi-page-application-form.utils";
import { isValidLengthWithoutDecimals } from "../../../../../redux/loan-application/loan-application.utils";
import { CONSOLIDATION_PURPOSES } from "../../../../../redux/loan-application/loan-application.constants";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  formState: UseFormTools<Application>["formState"];
  setValue: UseFormTools<Application>["setValue"];
}

export const AdditionalExpenses: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  formState,
  setValue,
  location,
}) => {
  const [spendingTotal, setSpendingTotal] = React.useState("");
  const saveProgress = useSaveLoanApplicationProgress(formData);
  const isEditing = useIsEditing(location);
  const loanParameters = useGetLoanParameters();
  const hasMortgagedInvestmentProperty =
    getMortgagedInvestmentProperty(formData);
  const isRefinancing =
    getIsPurposeSecurableByKey(
      formData.loanPurpose,
      loanParameters.loanPurpose
    ) && formData.assetSeller === "refinance";
  const fields = getAdditionalSpendingFields(formData);
  const isDisabled = isYourSpendingButtonDisabled(fields, formState);

  React.useEffect(() => {
    setSpendingTotal(calcSpendingTotal(fields));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const nextPage = () => {
    saveProgress();
    if (isEditing) {
      navigate("/application/review-your-details");
    } else {
      const skipToDebtList =
        CONSOLIDATION_PURPOSES.includes(formData.loanPurpose.toLowerCase()) ||
        hasMortgagedInvestmentProperty ||
        isRefinancing;

      if (skipToDebtList) {
        setValue("hasDebts", "yes");
        navigate("/application/debt-list");
      } else {
        navigate("/application/your-debts");
      }
    }
  };

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{additionalExpensesStyle}</style>

        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">
              Now, how much do you spend on the following additional expenses
              per month?
            </h2>
          </div>
          <div className="input-wrapper">
            {fields.map((field) => (
              <div className="field-wrapper" key={field.label}>
                <Field
                  type="currency"
                  label={field.label}
                  hasCommas={true}
                  {...fieldAdapter({
                    name: field.name,
                    min: [0],
                    validate: (value) =>
                      isValidLengthWithoutDecimals(value, 7) ||
                      `${field.label} expenses cannot be more than 7 digits (including 2 decimals)`,
                  })}
                ></Field>
                <p>/ per month</p>
              </div>
            ))}
          </div>
        </div>
        <div className="total-expenses-wrapper">
          <p>Monthly additional expenses:</p>

          <p>
            {spendingTotal !== "" ? (
              <strong>${spendingTotal}</strong>
            ) : (
              <strong>$0.00</strong>
            )}
          </p>
        </div>
        <div className="button-wrapper">
          <Button
            button="primary"
            theme="navy"
            size="large"
            fullWidth={true}
            disabled={isDisabled}
          >
            <button
              className="next-page-button"
              onClick={() => nextPage()}
              disabled={isDisabled}
            >
              {isEditing ? "back to review" : "next"}
            </button>
          </Button>
        </div>
      </div>
    </>
  );
};
