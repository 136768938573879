import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Button, IconButton, StatusCallout } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";
import { AppFormPages } from "../../../../shared/analytics/analytics.consts";

import { Application } from "../../../application";
import style from "../../multi-page-application-form.scss";
import { sendAppFormViewedEvent } from "../../multi-page-application-form.utils";
import driversLicence from "../../../../assets/images/drivers-licence.svg";
import medicare from "../../../../assets/images/medicare.svg";
import passport from "../../../../assets/images/passport.svg";
import idDocumentsStyle from "./id-documents.scss";
import {
  getIdDocDisabledFields,
  isIdSet,
  setFirstId,
} from "./utils/id-documents.utils";
import { useSaveLoanApplicationProgress } from "../../../../redux/loan-application/loan-application.hooks";
import { useIsEditing } from "../../hooks/use-is-editing";

interface IconButtonData {
  icon: string;
  label: string;
  subLabel?: string;
  isComplete: boolean;
  onClick: () => void;
}

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  setValue: UseFormTools<Application>["setValue"];
}

export const IdDocuments: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  setValue,
  location,
}) => {
  const saveProgress = useSaveLoanApplicationProgress(formData);
  const isEditing = useIsEditing(location);
  const idSet = isIdSet(formData);
  const twoIdsSet = Object.values(idSet).filter(Boolean).length > 1;

  const statusMessage: React.ReactElement = (
    <p>
      You&apos;ll need to supply <strong>two documents</strong> for us to
      verify. To get started choose your first form of ID.
    </p>
  );

  const completedMessage: React.ReactElement = (
    <p>
      <strong>After submitting</strong> your application{" "}
      <strong>we&apos;ll ask you to upload a copy</strong> of these documents.
    </p>
  );

  const hiddenDocFields = getIdDocDisabledFields(fieldAdapter);

  const iconButtonData: IconButtonData[] = [
    {
      icon: driversLicence,
      label: "Driver licence",
      subLabel: "Issued in Australia",
      isComplete: idSet.driversLicense,
      onClick: () => {
        navigate(
          isEditing
            ? "/application/drivers-licence?editing=true"
            : "/application/drivers-licence"
        );
      },
    },
    {
      icon: passport,
      label: "Passport",
      isComplete: idSet.passport,
      onClick: () => {
        navigate(
          isEditing
            ? "/application/passport?editing=true"
            : "/application/passport"
        );
      },
    },
    {
      icon: medicare,
      label: "Medicare Card",
      isComplete: idSet.medicare,
      onClick: () => {
        navigate(
          isEditing
            ? "/application/medicare?editing=true"
            : "/application/medicare"
        );
      },
    },
  ];

  const activeIconButtonData = twoIdsSet
    ? iconButtonData.filter((icon) => {
        return icon.isComplete === true;
      })
    : iconButtonData;

  const nextPage = () => {
    saveProgress();
    if (formData.livingSituationAtAddress === "owner") {
      navigate("/application/employment-and-income");
    } else {
      navigate("/application/living-situation");
    }
  };

  React.useEffect(() => {
    if (formData.idenfiticationSource1 !== "") {
      return;
    }

    setFirstId(formData, setValue);
  }, [formData, setValue]);

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.ID_DOCS);
  }, []);

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{idDocumentsStyle}</style>

        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">Let&apos;s verify your identity</h2>
            <p>We&apos;re checking that you&apos;re really you!</p>
          </div>

          {!twoIdsSet && (
            <div className="status-callout-wrapper">
              <StatusCallout
                type="coaching"
                theme="outline"
                message={statusMessage}
              />
            </div>
          )}

          <div className="id-buttons-wrapper">
            {activeIconButtonData.map((data) => (
              <div key={data.label}>
                <IconButton
                  icon={data.icon}
                  label={data.label}
                  subLabel={data.subLabel}
                  isActive={false}
                  isComplete={data.isComplete}
                  onClick={data.onClick}
                />
              </div>
            ))}
          </div>
          {twoIdsSet && (
            <div className="status-callout-wrapper">
              <StatusCallout
                type="coaching"
                theme="outline"
                message={completedMessage}
              />
            </div>
          )}
          {hiddenDocFields}
        </div>

        {twoIdsSet && (
          <div className="button-wrapper">
            <Button button="primary" theme="navy" size="large" fullWidth={true}>
              <button className="next-page-button" onClick={() => nextPage()}>
                next
              </button>
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
