import { RouteComponentProps } from "@reach/router";
import { isNotEmpty, toCurrency, UseFormTools } from "@wisr/common";
import { Button, StatusCallout } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { ContextCard } from "../../../../context-card/context-card";
import { ContextCardApplicationCopies } from "../../../../context-card/context-card.constants";
import { Application } from "../../../application";
import style from "../../multi-page-application-form.scss";
import startApplicationStyle from "./start-application.scss";
import { useGetApplicationHeaderState } from "../../../../redux/application-header/application-header.hooks";
import {
  EMPTY_LOAN_DATA,
  startApplicationStatusMessage,
} from "./start-application.constants";
import { useGetLoanParameters } from "../../../../loans/parameters/loan-parameters.hooks";
import { getUser } from "../../../../auth/auth.utils";
import { capitaliseName } from "./start-application.utils";

interface PropTypes extends RouteComponentProps {
  formData?: UseFormTools<Application>["formData"];
  firstNameParam?: string;
}

export const StartApplication: React.FC<PropTypes> = ({
  formData,
  firstNameParam,
}) => {
  const [loanData, setLoanData] = React.useState(EMPTY_LOAN_DATA);
  const applicationDetails = useGetApplicationHeaderState().applicationHeader;
  const loanPurposes = useGetLoanParameters().loanPurpose;

  const nextPage = () => {
    if (!getUser()) {
      if (applicationDetails?.returningUser === true) {
        navigate("/application/login");
      } else {
        navigate("/application/create-account");
      }
    } else {
      navigate("/application/requirements");
    }
  };

  React.useEffect(() => {
    if (isNotEmpty(loanPurposes)) {
      if (formData) {
        const purposeName = loanPurposes
          .find((purpose) => purpose.key === formData.loanPurpose.toLowerCase())
          ?.description.toLowerCase();
        const purposeNameText = purposeName ? " " + purposeName : "";
        setLoanData({
          firstName: formData.firstName,
          loanId: formData.loanId,
          loanPurposeText: purposeNameText,
          loanTerm: formData.loanTerm,
          loanAmount: formData.loanAmount,
        });
      } else if (
        !formData &&
        isNotEmpty(applicationDetails) &&
        firstNameParam
      ) {
        const loanIdString = applicationDetails.loanId.toString();
        const purposeName = loanPurposes
          .find((purpose) => purpose.key === applicationDetails.purpose)
          ?.description.toLowerCase();
        const purposeNameText = purposeName ? " " + purposeName : "";
        window.sessionStorage.setItem("loanId", loanIdString);
        setLoanData({
          firstName: firstNameParam,
          loanId: loanIdString,
          loanPurposeText: purposeNameText,
          loanTerm: (applicationDetails.term / 12).toString(),
          loanAmount: applicationDetails.amount.toString(),
        });
      }
    }
  }, [applicationDetails, firstNameParam, formData, loanPurposes]);

  if (loanData === EMPTY_LOAN_DATA) return null;

  return (
    <div className="multi-page-application">
      <style jsx>{style}</style>
      <style jsx>{startApplicationStyle}</style>

      <div className={`form-contents ${firstNameParam && "intro-page"}`}>
        <div className="heading-wrapper">
          <h2 className="heading">{`${capitaliseName(
            loanData.firstName
          )}, you're ready to apply for your${
            loanData.loanPurposeText
          } loan`}</h2>
        </div>
        <div className="body-text">
          <p>
            <strong>Your application #{loanData.loanId}</strong>
            <br />
            (We&apos;ll email this number for reference)
          </p>
          <p>
            You&apos;re applying for a
            <strong>{loanData.loanPurposeText}</strong> loan over a{" "}
            <strong>{loanData.loanTerm} year</strong> term for{" "}
            <strong>{toCurrency(+loanData.loanAmount, false)}</strong>
          </p>
        </div>
        <div className="status-callout-wrapper">
          <StatusCallout
            type="coaching"
            theme="outline"
            message={startApplicationStatusMessage}
          />
        </div>

        <ContextCard
          copy={ContextCardApplicationCopies.WISE_CHOICE}
          form="application"
        />

        <div className="button-wrapper">
          <Button button="primary" theme="navy" size="large" fullWidth={true}>
            <button className="next-page-button" onClick={() => nextPage()}>
              let&apos;s go
            </button>
          </Button>
        </div>
      </div>
    </div>
  );
};
