import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Button, Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";
import { AppFormPages } from "../../../../shared/analytics/analytics.consts";

import { Application } from "../../../application";
import { useIsEditing } from "../../hooks/use-is-editing";
import style from "../../multi-page-application-form.scss";
import { sendAppFormViewedEvent } from "../../multi-page-application-form.utils";
import {
  calcSpendingTotal,
  getYourSpendingFields,
  isYourSpendingButtonDisabled,
} from "./utils/your-spending.utils";
import spendingStyle from "./your-spending.scss";
import { useSaveLoanApplicationProgress } from "../../../../redux/loan-application/loan-application.hooks";
import { isValidLengthWithoutDecimals } from "../../../../redux/loan-application/loan-application.utils";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  formState: UseFormTools<Application>["formState"];
}

export const YourSpending: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  formState,
  location,
}) => {
  const [spendingTotal, setSpendingTotal] = React.useState("");
  const saveProgress = useSaveLoanApplicationProgress(formData);

  const fields = getYourSpendingFields(formData);
  const isDisabled =
    calcSpendingTotal(fields) === "0.00" ||
    isYourSpendingButtonDisabled(fields, formState);
  const isEditing = useIsEditing(location);

  const nextPage = () => {
    saveProgress();
    if (isEditing) {
      navigate("/application/review-your-details");
    } else {
      navigate("/application/additional-expenses");
    }
  };

  React.useEffect(() => {
    setSpendingTotal(calcSpendingTotal(fields));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.SPENDING);
  }, []);

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{spendingStyle}</style>

        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">
              How much is your total household living expenses?
            </h2>

            <p>
              We need to better understand your spending habits. Do your best to
              fill this out as accurately as possible.
            </p>
            <p>
              <strong>
                You don&apos;t need to include your rent, mortgage, credit cards
                or other debts.
              </strong>
            </p>
          </div>

          <div className="input-wrapper">
            {fields.map((field) => (
              <div className="field-wrapper" key={field.label}>
                <Field
                  type="currency"
                  label={field.label}
                  hasCommas={true}
                  {...fieldAdapter({
                    name: field.name,
                    min: [0],
                    validate: (value) =>
                      isValidLengthWithoutDecimals(value, 7) ||
                      `${field.label} expenses cannot be more than 7 digits (including 2 decimals)`,
                  })}
                  hint={field.hint}
                ></Field>
                <p>/ per month</p>
              </div>
            ))}
          </div>
        </div>
        <div className="total-expenses-wrapper">
          <p>Total monthly expenses:</p>
          <p>
            {spendingTotal !== "" ? (
              <strong>${spendingTotal}</strong>
            ) : (
              <strong>$0.00</strong>
            )}
          </p>
        </div>
        <div className="button-wrapper">
          <Button
            button="primary"
            theme="navy"
            size="large"
            fullWidth={true}
            disabled={isDisabled}
          >
            <button
              className="next-page-button"
              onClick={() => nextPage()}
              disabled={isDisabled}
            >
              {isEditing ? "back to review" : "next"}
            </button>
          </Button>
        </div>
      </div>
    </>
  );
};
