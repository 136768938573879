import React from "react";

const allTypes = [
  "email",
  "tel-national",
  "mobile tel-national",
  "home tel-national",
  "work tel-national",
  "honorific-prefix",
  "given-name",
  "additional-name",
  "family-name",
  "street-address",
  "address-line1",
  "address-line2",
  "address-line3",
  "address-level1",
];

// This component resolves our autofill issues by provinging a bunch of
// better autofill targets for google to throw it's junk into, rather
// than putting them into the customers fields.
//
// This also has the benefit of solving the problem without breaking standards
// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
export const AutoFillFix: React.FC<{
  section: string;
  safeTypes: string[];
}> = ({ section, safeTypes }) => {
  const types = allTypes.filter((type) => !safeTypes.includes(type));

  return (
    <div>
      <style jsx>
        {`
          div {
            position: absolute;
            bottom: 100%;
            right: 100%;
            height: 0px;
            width: 0px;
            overflow: hidden;
          }
        `}
      </style>
      {types.map((type) => {
        const autocompleteTokens = `section-${section} ${type}`;
        const inputName = autocompleteTokens.replace(/\s/g, "-");

        return (
          <label key={autocompleteTokens}>
            {autocompleteTokens}
            <input name={inputName} autoComplete={autocompleteTokens} />
          </label>
        );
      })}
    </div>
  );
};
