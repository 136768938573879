import { useSelector } from "react-redux";
import { AppStore } from "../../store";
import { ApplicationTermsState } from "../../types/terms";
import { selectApplicationTermsState } from "./application-terms.slice";

export const useGetApplicationTermsState = () => {
  return useSelector<AppStore, ApplicationTermsState>(
    selectApplicationTermsState
  );
};
