import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import React from "react";
import dayjs from "dayjs";

import { Application } from "../../../../application";
import { ID_TYPES } from "../../../../application.utils";
import { medicareCardOptions } from "../medicare/medicare.constants";
import { passportCountryList } from "../passport/passport.constants";
import { australianStateOptions } from "../id-documents.consts";

interface IdSetObject {
  driversLicense: boolean;
  passport: boolean;
  medicare: boolean;
}

export const isIdSet = (
  formData: UseFormTools<Application>["formData"]
): IdSetObject => {
  const idSourceList = [
    formData.idenfiticationSource1,
    formData.idenfiticationSource2,
  ];

  return {
    driversLicense: idSourceList.includes(ID_TYPES.DRIVERS_LICENCE),
    passport: idSourceList.includes(ID_TYPES.PASSPORT),
    medicare: idSourceList.includes(ID_TYPES.MEDICARE),
  };
};

export const getIdentitySource = (
  formData: UseFormTools<Application>["formData"],
  idType: "Passport" | "Medicare card" | "Driver's License"
) => {
  if (formData.idenfiticationSource1 === "") {
    return `idenfiticationSource1`;
  } else if (formData.idenfiticationSource2 === "") {
    return `idenfiticationSource2`;
  } else {
    if (idType === formData.idenfiticationSource1) {
      return `idenfiticationSource1`;
    }
  }
  return `idenfiticationSource2`;
};

export const setFirstId = (
  formData: UseFormTools<Application>["formData"],
  setValue: UseFormTools<Application>["setValue"]
) => {
  const identityIndex = `idenfiticationSource1`;

  if (
    formData.driversLicenceState &&
    formData.driverLicenceNumber &&
    formData.driverLicenceCardNumber &&
    formData.driversLicenceExpiry
  ) {
    setValue(identityIndex, ID_TYPES.DRIVERS_LICENCE);
    return;
  } else if (
    formData.medicareCardNumber &&
    formData.medicareColour &&
    formData.medicareReferenceNumber &&
    formData.medicareExpiry
  ) {
    setValue(identityIndex, ID_TYPES.MEDICARE);
    return;
  } else if (formData.passportCountry && formData.passportNumber) {
    setValue(identityIndex, ID_TYPES.PASSPORT);
    return;
  }
};

export const getIdDocDisabledFields = (
  fieldAdapter: UseFormTools<Application>["fieldAdapter"]
) => {
  return (
    <>
      <Field
        id={`form_application_input_licence_state`}
        layout="single"
        label="State of issue"
        type="select"
        placeholder="Select an option"
        options={australianStateOptions}
        required
        {...fieldAdapter({
          name: "driversLicenceState",
          required: "Please enter your licence state",
          condition: false,
        })}
      />
      <Field
        id={`form_application_input_licence_number`}
        label="Licence number "
        placeholder="- - - - - - - - - -"
        type="text"
        required
        {...fieldAdapter({
          name: "driverLicenceNumber",
          required: "Please enter your drivers licence",
          condition: false,
        })}
      />
      <Field
        id={`form_application_input_licence_cardnumber`}
        label="Card number"
        type="text"
        placeholder="- - - - - - - - - -"
        required
        {...fieldAdapter({
          name: "driverLicenceCardNumber",
          required: "Please enter licence card number",
          minLength: [
            6,
            "Licence card number must be at least 6 characters long",
          ],
          maxLength: [
            10,
            "Licence card number cannot be more than 10 characters long",
          ],
          condition: false,
        })}
      />
      <Field
        type="date"
        label="Expiry date"
        {...fieldAdapter({
          name: "driversLicenceExpiry",
          required: "Driver licence expiry date is required",
          condition: false,
        })}
      />

      <Field
        id={`form_application_input_passport_country_option`}
        label="Country of issue"
        type="select"
        placeholder="Select an option"
        options={passportCountryList}
        required
        {...fieldAdapter({
          name: "passportCountry",
          required: "Please select a country of issue",
          condition: false,
        })}
      />
      <Field
        id={`form_application_input_passport_number`}
        label="Passport number"
        placeholder="Enter Passport number"
        type="text"
        required
        {...fieldAdapter({
          name: "passportNumber",
          required: "Please enter your passport number",
          condition: false,
        })}
      />

      <Field
        type="number"
        label="Card number"
        placeholder="---- ----- -"
        maxLength={10}
        {...fieldAdapter({
          name: "medicareCardNumber",
          required: "Medicare card number is required",
          minLength: [10, "Medicare card number must be 10 digits long"],
          maxLength: [10, "Medicare card number must be 10 digits long"],
          condition: false,
        })}
      />
      <Field
        type="text"
        label="Middle name initial (optional)"
        {...fieldAdapter({
          name: "medicareInitial",
          condition: false,
        })}
      />
      <Field
        type="select"
        label="Card colour"
        placeholder="Please select..."
        options={medicareCardOptions}
        {...fieldAdapter({
          name: "medicareColour",
          required: "Medicare card colour is required",
          condition: false,
        })}
      />
      <Field
        type="select"
        label="Reference number"
        placeholder="Please select..."
        options={["1", "2", "3", "4", "5", "6", "7", "8", "9"]}
        {...fieldAdapter({
          name: "medicareReferenceNumber",
          required: "Medicare reference number is required",
          condition: false,
        })}
      />
      <Field
        type="date"
        date="partial"
        label="Expiry date"
        {...fieldAdapter({
          name: "medicareExpiry",
          required: "Medicare expiry date is required",
          condition: false,
        })}
      />
    </>
  );
};

export const validDriverLicenceExpiry = (expiryDate: string) => {
  return dayjs(expiryDate, "DD / MM / YYYY").isAfter(dayjs());
};

export const validMedicareCardExpiry = (expiryDate: string) => {
  return dayjs(expiryDate, "MM / YYYY").isAfter(dayjs());
};
