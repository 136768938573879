import { Button } from "@wisr/react-ui";
import React from "react";
import { useDispatch } from "react-redux";
import { useGetPartnerReferral } from "../../../../redux/partner-referral/partner-referral-widget.hooks";
import { useGetWisrProfile } from "../../../../redux/wisr-profile/wisr-profile.hooks";
import { Events } from "../../../../shared/analytics/analytics.consts";
import {
  gtmSendEvent,
  quoteAmplitudeHelper,
} from "../../../../shared/analytics/analytics.utils";
import { LoaderFullPage } from "../../../../shared/loader-full-page/loader-full-page";
import { FetchStatus } from "../../../../shared/redux.consts";
import {
  partnerReferralBodyCopy,
  LOAN_REFERRAL_PRODUCT,
  partnerReferralBodyCopyAlternate,
  partnerReferralListCopy,
} from "./partner-referral-screen.consts";
import { submitForPartnerReferral } from "../../../../redux/partner-referral/partner-referral-widget.thunk";
import drivaLogo from "../../../../assets/images/driva-logo.svg";
import style from "./partner-referral-screen.scss";
import { getPartnerReferralTitleCopy } from "./partner-referral-screen.utils";

interface PartnerReferralScreenProps {
  drivaNewDesignEnabled: boolean;
}

export const PartnerReferralScreen: React.FC<PartnerReferralScreenProps> = ({
  drivaNewDesignEnabled,
}) => {
  const dispatch = useDispatch();
  const wisrProfile = useGetWisrProfile();
  const partnerReferralLoading =
    useGetPartnerReferral().status === FetchStatus.LOADING;
  const buttonText = drivaNewDesignEnabled
    ? "Check my options with Driva"
    : "Get started with Driva";

  const submitPartnerReferral = () => {
    if (wisrProfile?.wisrCustomerId) {
      dispatch(
        submitForPartnerReferral({
          application: "loan-application",
          wisrCustomerId: wisrProfile.wisrCustomerId,
        })
      );
    }
  };

  React.useEffect(() => {
    if (wisrProfile) {
      quoteAmplitudeHelper.setUser(wisrProfile.wisrCustomerId);
      quoteAmplitudeHelper.sendEvent(
        Events.PRESENTED_DRIVA,
        {
          "Product offer": LOAN_REFERRAL_PRODUCT,
        },
        {
          "Driva design": drivaNewDesignEnabled ? "third" : "old",
        }
      );
      gtmSendEvent(Events.PRESENTED_DRIVA, {
        productOffer: LOAN_REFERRAL_PRODUCT,
      });
    }
  }, [drivaNewDesignEnabled, wisrProfile]);

  return (
    <div
      className={`partner-referral-screen ${
        drivaNewDesignEnabled ? "alternate" : ""
      }`}
    >
      <style jsx>{style}</style>
      <div className="intro">
        {getPartnerReferralTitleCopy(
          drivaNewDesignEnabled,
          wisrProfile?.firstName
        )}
      </div>
      {drivaNewDesignEnabled ? (
        <div className="body alternate">
          <div className="image-column">
            <div className="logo-section">
              <div className="image-section">
                <img src={drivaLogo} alt="Driva logo" className="driva-logo" />
              </div>
            </div>
          </div>
          <div className="content-column">
            <div className="content-wrapper">
              {partnerReferralBodyCopyAlternate}
              <div className="logo-section">
                <div className="image-section">
                  <img
                    src={drivaLogo}
                    alt="Driva logo"
                    className="driva-logo"
                  />
                </div>
              </div>
              <div className="list">{partnerReferralListCopy}</div>
              <div className="button-wrapper">
                <Button
                  button="primary"
                  theme="navy"
                  fullWidth
                  disabled={partnerReferralLoading}
                >
                  <button
                    onClick={submitPartnerReferral}
                    disabled={partnerReferralLoading}
                  >
                    {buttonText}
                  </button>
                </Button>
              </div>
              <div className="disclaimer-alternate">
                <p>
                  <strong>IMPORTANT INFORMATION</strong>
                </p>
                <ul>
                  <li>
                    By clicking the button above, we will share some of the
                    information you provided and the team at Driva may contact
                    you to discuss your needs.
                  </li>
                  <li>
                    If Driva helps you get a loan, Wisr will receive a
                    commission.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="body">
            <div className="image-section">
              <img src={drivaLogo} alt="Driva logo" className="driva-logo" />
            </div>
            <div className="content-section">
              {partnerReferralBodyCopy}
              <div className="button-wrapper">
                <Button
                  button="primary"
                  theme="navy"
                  fullWidth
                  disabled={partnerReferralLoading}
                >
                  <button
                    onClick={submitPartnerReferral}
                    disabled={partnerReferralLoading}
                  >
                    {buttonText}
                  </button>
                </Button>
              </div>
            </div>
          </div>
          <div className="disclaimer">
            <p>
              <strong>IMPORTANT INFORMATION</strong>
            </p>
            <ul>
              <li>
                By clicking “{buttonText}”, you are giving Driva permission to
                contact you and access your loan details.
              </li>
              <li>
                If your referral is successful, Wisr will earn a commission.
              </li>
            </ul>
          </div>
        </>
      )}
      <LoaderFullPage enabled={partnerReferralLoading} />
    </div>
  );
};
