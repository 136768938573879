/* eslint-disable @typescript-eslint/no-unused-vars */
import { UseFormTools } from "@wisr/common";

import { Application } from "../../../../application";
import { SpendingFields } from "../your-spending.constants";

export const calcSpendingTotal = (fields: SpendingFields[]) => {
  const total = fields
    .map((field) =>
      field.value !== "" ? parseFloat(field.value) : parseFloat("0")
    )
    .reduce((partialSum, a) => partialSum + a, 0);

  return total.toFixed(2);
};

export const isYourSpendingButtonDisabled = (
  fields: SpendingFields[],
  formState: UseFormTools<Application>["formState"]
) => {
  return fields.some((field) => {
    return formState[field.name].errors.length > 0;
  });
};

export const getYourSpendingFields = (
  formData: UseFormTools<Application>["formData"]
): SpendingFields[] => {
  return [
    {
      label: "Groceries",
      name: "groceries",
      value: formData.groceries,
    },
    {
      label: "Clothing",
      name: "clothing",
      value: formData.clothing,
    },
    {
      label: "Council rates",
      name: "council_rates",
      value: formData.council_rates,
    },
    {
      label: "Car/Motorbike registrations",
      name: "car_registration",
      value: formData.car_registration,
    },
    {
      label: "Petrol/Maintenance",
      name: "petrol",
      value: formData.petrol,
    },
    {
      label: "Car and home insurance",
      name: "car_home_insurance",
      value: formData.car_home_insurance,
    },
    {
      label: "Utilities",
      name: "utilities",
      hint: "Electricity, water, gas",
      value: formData.utilities,
    },
    {
      label: "Communication bills",
      name: "communication",
      hint: "Landline, mobile, internet",
      value: formData.communication,
    },
    {
      label: "Transportation",
      name: "transportation",
      value: formData.transportation,
    },
    {
      label: "Entertainment",
      name: "entertainment",
      hint: "Eating out, holidays, sports, hobbies",
      value: formData.entertainment,
    },
    {
      label: "Other",
      name: "other_general_expenses",
      value: formData.other_general_expenses,
    },
  ];
};

export const getAdditionalSpendingFields = (
  formData: UseFormTools<Application>["formData"]
): SpendingFields[] => {
  return [
    {
      label: "Health insurance",
      name: "health_insurance",
      value: formData.health_insurance,
    },
    {
      label: "Private education",
      name: "education",
      value: formData.education,
    },
    {
      label: "Second motor vehicle costs",
      name: "seconds_motor_vehicle",
      value: formData.seconds_motor_vehicle,
    },
  ];
};
