import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { isValidDate, UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import React from "react";

import { Application } from "../../../../application";
import { ID_TYPES } from "../../../../application.utils";
import style from "../../../multi-page-application-form.scss";
import { SecurityMsg } from "../../../security-msg/security-msg";
import idDocsStyle from "../id-documents.scss";
import { DocumentSubmit } from "../status-and-submit/document-submit";
import { medicareCardOptions } from "./medicare.constants";
import { AppFormPages } from "../../../../../shared/analytics/analytics.consts";
import { sendAppFormViewedEvent } from "../../../multi-page-application-form.utils";
import { validMedicareCardExpiry } from "../utils/id-documents.utils";
interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  setValue: UseFormTools<Application>["setValue"];
  formState: UseFormTools<Application>["formState"];
}

export const Medicare: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  setValue,
  formState,
  location,
}) => {
  const isDisabled =
    !formData.medicareCardNumber ||
    !formData.medicareColour ||
    !formData.medicareExpiry ||
    !formData.medicareReferenceNumber ||
    formState.medicareCardNumber.errors.length > 0 ||
    formData.medicareInitial.length > 1 ||
    formState.medicareInitial.errors.length > 0 ||
    formState.medicareExpiry.errors.length > 0;

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.MEDICARE);
  }, []);

  return (
    <div className="multi-page-application">
      <style jsx>{style}</style>
      <style jsx>{idDocsStyle}</style>

      <div className="form-contents">
        <div className="heading-wrapper">
          <h2 className="heading">Medicare card details</h2>
        </div>

        <SecurityMsg />

        <div className="input-wrapper">
          <Field
            type="number"
            label="Card number"
            placeholder="---- ----- -"
            maxLength={10}
            {...fieldAdapter({
              name: "medicareCardNumber",
              required: "Medicare card number is required",
              pattern: [
                /^\d{10}$/,
                "Medicare card number must be 10 digits long",
              ],
            })}
          />
          <Field
            type="text"
            label="Middle name initial (optional)"
            {...fieldAdapter({
              name: "medicareInitial",
              maxLength: [1, "Middle name initial must be 1 character long"],
              pattern: [/^[a-zA-Z]$/, "Middle name initial must be a letter"],
            })}
          />
          <Field
            type="select"
            label="Card colour"
            placeholder="Please select..."
            options={medicareCardOptions}
            {...fieldAdapter({
              name: "medicareColour",
              required: "Medicare card colour is required",
            })}
          />
          <Field
            type="select"
            label="Reference number"
            placeholder="Please select..."
            options={["1", "2", "3", "4", "5", "6", "7", "8", "9"]}
            {...fieldAdapter({
              name: "medicareReferenceNumber",
              required: "Medicare reference number is required",
            })}
          />
          <Field
            type="date"
            date="partial"
            label="Expiry date"
            {...fieldAdapter({
              name: "medicareExpiry",
              required: "Medicare expiry date is required",
              validate: (value) => {
                if (!isValidDate(`01 / ${value}`))
                  return "Please enter a valid expiry date in the format MM / YYYY";
                if (!validMedicareCardExpiry(value))
                  return "Medicare card cannot be expired";
                return true;
              },
            })}
          />
        </div>
      </div>
      <DocumentSubmit
        isDisabled={isDisabled}
        location={location}
        setValue={setValue}
        formData={formData}
        idType={ID_TYPES.MEDICARE}
      />
    </div>
  );
};
