import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import {
  Button,
  CreditSenseResult,
  Loader,
  StatusCallout,
} from "@wisr/react-ui";
import dayjs from "dayjs";
import { navigate } from "gatsby";
import React from "react";

import { ContextCard } from "../../../../context-card/context-card";
import { ContextCardApplicationCopies } from "../../../../context-card/context-card.constants";
import { CreditSenseIframeComponent } from "../../../../credit-sense/credit-sense-iframe.component";
import { AppFormPages } from "../../../../shared/analytics/analytics.consts";

import { getConfig } from "../../../../shared/config.utils";
import { Application } from "../../../application";
import { HelpCallout } from "../../help-callout/help-callout";
import { useIsEditing } from "../../hooks/use-is-editing";
import { InfoModal } from "../../info-modal/info-modal";
import style from "../../multi-page-application-form.scss";
import { sendAppFormViewedEvent } from "../../multi-page-application-form.utils";
import { SecurityMsg } from "../../security-msg/security-msg";
import bankStatementsStyle from "./bank-statements.scss";
import { useSaveLoanApplicationProgress } from "../../../../redux/loan-application/loan-application.hooks";
import { useFlags } from "launchdarkly-react-client-sdk";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  setValue: UseFormTools<Application>["setValue"];
}

export const BankStatements: React.FC<PropTypes> = ({
  formData,
  setValue,
  location,
}) => {
  const [infoModal, setInfoModal] = React.useState(false);
  const [dataKey, setDataKey] = React.useState("");
  const { CREDIT_SENSE_KEY_V1, CREDIT_SENSE_KEY_V2, SHOW_DEBUG_TOOLS } =
    getConfig();
  const { ingestCreditSenseV1AndV2 } = useFlags();
  const saveProgress = useSaveLoanApplicationProgress(formData);

  const TARGET_CREDIT_SENSE_KEY = ingestCreditSenseV1AndV2
    ? CREDIT_SENSE_KEY_V2
    : CREDIT_SENSE_KEY_V1;

  const isDisabled = !(
    formData.creditSenseResult === CreditSenseResult.Success ||
    formData.creditSenseResult === CreditSenseResult.Complete
  );
  const isEditing = useIsEditing(location);
  const modalHeading = "Why do we require this?";

  const modalDescription = (
    <>
      <style jsx>{bankStatementsStyle}</style>
      <ul>
        <li>a one-off connection.</li>
        <li>As secure as a bank.</li>
      </ul>
      <p>
        To help us verify your details, Wisr uses CreditSense to securely
        provide us with your bank statements for the last 90 days.
      </p>
      <p>
        We don&apos;t see or store your login details and we can&apos;t see
        anything other than your bank statements.
      </p>
      <p>
        For more information on CreditSense visit their website{" "}
        <a
          href="https://www.creditsense.com.au/consumers"
          target="_blank"
          rel="noopener noreferrer"
        >
          here.
        </a>
      </p>
    </>
  );

  const nextPage = () => {
    saveProgress();
    if (isEditing) {
      navigate("/application/review-your-details");
    } else {
      navigate("/application/significant-changes");
    }
  };

  React.useEffect(() => {
    setDataKey(dayjs().format());
  }, []);

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.STATEMENTS);
  }, []);

  return (
    <div>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{bankStatementsStyle}</style>

        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">
              Please provide your latest bank statements
            </h2>
          </div>
          <div className="callout-wrapper">
            <HelpCallout
              text="Why do we require this?"
              setInfoModal={setInfoModal}
            />
          </div>
          <div className="security-message-wrapper">
            <SecurityMsg />
          </div>

          <div className="credit-sense-wrapper">
            {!TARGET_CREDIT_SENSE_KEY ? (
              <StatusCallout
                type="error"
                message="No CREDIT_SENSE_KEY provided"
              />
            ) : !formData || dataKey === "" ? (
              <Loader loading />
            ) : (
              <CreditSenseIframeComponent
                dataKey={dataKey}
                client={TARGET_CREDIT_SENSE_KEY}
                loanId={formData.loanId}
                allowDebugBanks={SHOW_DEBUG_TOOLS}
                onResult={(response) => {
                  if (response === formData.creditSenseResult) {
                    return;
                  }
                  return setValue("creditSenseResult", response);
                }}
              />
            )}
          </div>
        </div>
        <ContextCard
          copy={ContextCardApplicationCopies.SAME_DAY}
          form="application"
        />
        <div className="button-wrapper">
          {formData.allowBypassCreditSense === "true" && (
            <Button
              button="secondary"
              theme="navy"
              size="large"
              fullWidth={true}
            >
              <button className="next-page-button" onClick={() => nextPage()}>
                Skip and upload later
              </button>
            </Button>
          )}
          <Button
            button="primary"
            theme="navy"
            size="large"
            fullWidth={true}
            disabled={isDisabled}
          >
            <button
              className="next-page-button"
              onClick={() => nextPage()}
              disabled={isDisabled}
            >
              {isEditing ? "back to review" : "next"}
            </button>
          </Button>
        </div>
      </div>
      <InfoModal
        infoModal={infoModal}
        setInfoModal={setInfoModal}
        heading={modalHeading}
        description={modalDescription}
      />
    </div>
  );
};
