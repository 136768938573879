import { useSelector } from "react-redux";
import { AppStore } from "../../store";
import { ApplicationLoginState } from "../../types/application-login";
import { selectApplicationLoginState } from "./application-login.slice";

export const useGetApplicationLoginState = () => {
  return useSelector<AppStore, ApplicationLoginState>(
    selectApplicationLoginState
  );
};
