import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import React from "react";

import { Application } from "../../../../application";
import style from "../../../multi-page-application-form.scss";
import termsStyle from "./terms-list.scss";
import { useGetApplicationTermsState } from "../../../../../redux/application-terms/application-terms.hooks";
import { convertTermsToJSX } from "./terms-list.utils";

export const TermsList = ({
  fieldAdapter,
  setValue,
}: {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  setValue: UseFormTools<Application>["setValue"];
}) => {
  const termsState = useGetApplicationTermsState();
  const [showCommunicationConsent, setShowCommunicationConsent] =
    React.useState(false);

  /* istanbul ignore next */
  const toggleCommunicationConsent = React.useCallback(
    (e) => {
      e.preventDefault();
      setShowCommunicationConsent(!showCommunicationConsent);
    },
    [showCommunicationConsent]
  );

  const terms = convertTermsToJSX(termsState.terms);

  React.useEffect(() => {
    setValue("termsAndConditionsAgreement", []);
  }, [setValue]);

  React.useEffect(() => {
    const communicationConsentLink = document.getElementById(
      "communicationConsentLink"
    );
    /* istanbul ignore next */
    communicationConsentLink?.addEventListener(
      "click",
      toggleCommunicationConsent
    );
  }, [toggleCommunicationConsent, termsState]);

  return (
    <>
      <style jsx>{style}</style>
      <style jsx>{termsStyle}</style>

      <div className="terms-wrapper">
        <p className="paragraph-heading">Terms, Privacy & Consent</p>
        {terms.termsSection}
        {showCommunicationConsent && terms.communicationConsentSection}
        <div className="terms-checkbox-wrapper">
          <Field
            type="checkbox"
            label=""
            options={[
              "I have read, understood and agree to the terms and conditions above.",
            ]}
            {...fieldAdapter({
              name: "termsAndConditionsAgreement",
              required:
                "You must agree to the terms and conditions before submitting your application",
            })}
          ></Field>
        </div>
      </div>
    </>
  );
};
