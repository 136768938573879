import React from "react";
import {
  LoanOfferOption,
  RateEstimateResponseObject,
} from "../../../../types/rate-estimate";
import { Field } from "@wisr/react-ui";

export const generateOfferRadios = (
  rateEstimateResponse: RateEstimateResponseObject,
  selectedLoanTerm: string,
  setSelectedLoanTerm: (arg0: string) => void
) => {
  if (!rateEstimateResponse.indicativeQuotes) return [];

  const updatedOffers: LoanOfferOption[] =
    rateEstimateResponse.indicativeQuotes.map((quote) => {
      let highlight = "";
      let highlightInfo = "";

      switch (quote.term) {
        case 3:
          highlight = "Pay it off fast 🏃";
          highlightInfo = "🏃 This is our shortest loan term";
          break;
        case 5:
          highlight = "You’re in good company 🧡";
          highlightInfo = "🧡 38% of customers choose this loan term.";
          break;
        case 7:
          highlight = "Lowest repayments 💰";
          highlightInfo = "💰 You’ll pay less each month with this option.";
          break;
      }

      return {
        ...quote,
        highlight,
        highlightInfo,
      };
    });

  return updatedOffers
    .slice()
    .sort((a, b) => a.term - b.term)
    .map((offer, i) => (
      <div key={i} className="offers">
        <Field
          type="radio-info"
          label=""
          options={[`${offer.term}`]}
          highlight={offer.highlight}
          highlightInfo={offer.highlightInfo}
          content={
            <div className="offer-content" key={offer.term}>
              <div className="row">
                <div className="column">
                  <span className="bold">{`${offer.term} year`}</span>
                  <span>Term</span>
                </div>
                <div className="column">
                  <span className="bold">{`${offer.rate}%^`}</span>
                  <span>Indicative rate p.a.</span>
                </div>
                <div className="column">
                  <span className="bold">{`$${
                    offer.monthlyRepayment +
                    rateEstimateResponse.fees.monthlyAccountFee
                  }`}</span>
                  <span>Monthly repayment*</span>
                </div>
              </div>
              <div className="row"></div>
            </div>
          }
          onChange={() => setSelectedLoanTerm(`${offer.term}`)}
          value={selectedLoanTerm}
        />
      </div>
    ));
};
