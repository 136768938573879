import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import React from "react";

import { AppFormPages } from "../../../../shared/analytics/analytics.consts";
import { Application } from "../../../application";
import { InfoModal } from "../../info-modal/info-modal";
import style from "../../multi-page-application-form.scss";
import { sendAppFormViewedEvent } from "../../multi-page-application-form.utils";
import loanTermStyle from "./loan-term.scss";
import { useGetRateEstimateState } from "../../../../redux/rate-estimate/rate-estimate.hooks";
import { generateOfferRadios } from "./loan-term.utils";
import { useGetWisrApplicationId } from "../../../../widgets/borrowing/personal-loan/personal-loan-borrowing-widget.hooks";
import { useDispatch } from "react-redux";
import { FetchStatus } from "../../../../shared/redux.consts";
import { fetchRateEstimate } from "../../../../redux/rate-estimate/rate-estimate.thunk";
import { Button } from "@wisr/react-ui";
import { navigate } from "gatsby";
import { GATEWAY_API } from "../../../../shared/url.consts";
import axios from "axios";
import { fetchEstimateAccept } from "../../../../redux/estimate-accept/estimate-accept.thunk";
import { useSaveLoanApplicationProgress } from "../../../../redux/loan-application/loan-application.hooks";

type PropTypes = RouteComponentProps & {
  formData: UseFormTools<Application>["formData"];
  setValue: UseFormTools<Application>["setValue"];
};

export const LoanTerm: React.FC<PropTypes> = ({ formData, setValue }) => {
  const dispatch = useDispatch();
  const wisrApplicationId = useGetWisrApplicationId();
  const rateEstimateState = useGetRateEstimateState();
  const rateEstimateResponse =
    rateEstimateState.rateEstimateResponse?.rateEstimate;
  const quoteId = rateEstimateState.rateEstimateResponse?.quoteId;

  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedLoanTerm, setSelectedLoanTerm] = React.useState<string>(
    formData.loanTerm
  );
  const saveProgress = useSaveLoanApplicationProgress({
    ...formData,
    loanTerm: selectedLoanTerm,
  });
  const hasMonthlyFee =
    rateEstimateResponse && rateEstimateResponse.fees.monthlyAccountFee
      ? rateEstimateResponse.fees.monthlyAccountFee > 0
      : false;
  const modalHeading = "Fees";
  const modalDescription = (
    <div className="offer-modal">
      <ul>
        <li>
          <strong>${rateEstimateResponse?.fees.totalEstablishmentFee}</strong>{" "}
          Establishment fee
        </li>
        {rateEstimateResponse && hasMonthlyFee && (
          <li>
            <strong>${rateEstimateResponse.fees.monthlyAccountFee}</strong>{" "}
            Monthly Account fee
          </li>
        )}
        <li>
          <strong>$0</strong> Early repayment or exit fees
        </li>
      </ul>
      <p className="disclaimer-text">
        <strong>* Excludes any late fees</strong>
      </p>
    </div>
  );

  const nextPage = () => {
    if (quoteId) {
      axios
        .post<void>(`${GATEWAY_API}/loanterm`, {
          LoanId: +formData.loanId,
          LoanQuoteId: quoteId,
          LoanTerm: +selectedLoanTerm * 12,
        })
        .then(() => undefined);
      dispatch(
        fetchEstimateAccept({ quoteId: quoteId, term: +selectedLoanTerm * 12 })
      );
    }
    setValue("loanTerm", selectedLoanTerm);
    saveProgress();
    navigate("/application/review-your-details");
  };

  React.useEffect(() => {
    if (rateEstimateState.getStatus === FetchStatus.IDLE && wisrApplicationId) {
      dispatch(
        fetchRateEstimate({
          wisrApplicationId: wisrApplicationId,
          partnerCompanyId: formData.partnerCompanyId,
        })
      );
    }
  }, [
    dispatch,
    formData.partnerCompanyId,
    rateEstimateState.getStatus,
    wisrApplicationId,
  ]);

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.LOAN_TERM);
  }, []);

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{loanTermStyle}</style>

        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">Choose your loan term:</h2>
          </div>
        </div>

        <div className="subgrid middle-row">
          {rateEstimateResponse &&
            generateOfferRadios(
              rateEstimateResponse,
              selectedLoanTerm,
              setSelectedLoanTerm
            )}
        </div>

        <p className="small">
          Loan repayments include all{" "}
          <button onClick={() => setModalOpen(true)} type="button">
            fees
          </button>
          *
        </p>

        <div className="button-wrapper">
          <Button button="primary" theme="navy" size="large" fullWidth={true}>
            <button className="next-page-button" onClick={() => nextPage()}>
              back to review
            </button>
          </Button>
        </div>
      </div>
      <InfoModal
        infoModal={modalOpen}
        setInfoModal={setModalOpen}
        heading={modalHeading}
        description={modalDescription}
      />
    </>
  );
};
