import { UseFormTools } from "@wisr/common";
import { Field, StatusCallout } from "@wisr/react-ui";
import React from "react";

import { Application } from "../../../../application";
import {
  emptyCurrencyValues,
  paymentFrequencyOptions,
} from "../../../../application-form.constants";
import style from "../../../multi-page-application-form.scss";
import {
  CREDIT_CARD_VALUE,
  debtInitialList,
  DebtListItem,
  debtTypeOptions,
  MORTGAGE_VALUE,
  PERSONAL_LOAN_VALUE,
} from "./debt-list.constants";
import debtListStyle from "./debt-list.scss";
import { isValidLengthWithoutDecimals } from "../../../../../redux/loan-application/loan-application.utils";
import { CONSOLIDATION_PURPOSES } from "../../../../../redux/loan-application/loan-application.constants";

export const getRadioValue = (value: string[]) => {
  if (value.length > 0) {
    return "Yes";
  }
  return "No";
};

export const isRadioValueYes = (value: string[]) => {
  if (value.length > 0 && value[0].toLowerCase() !== "no") {
    return "Yes";
  }
  return "No";
};

export const setRadioValue = (
  value: string,
  setValue: UseFormTools<Application>["setValue"],
  name: keyof Application,
  data: string
) => {
  if (value === "Yes") {
    setValue(name, [data]);
  } else {
    setValue(name, []);
  }
};

export const getDebtListInitialValues = (
  formData: UseFormTools<Application>["formData"]
) => {
  return debtInitialList.map((listItem) => {
    if (formData[listItem.type]) {
      return { ...listItem, active: true };
    }
    return listItem;
  });
};

export const getMortgagedInvestmentProperty = (
  formData: UseFormTools<Application>["formData"]
) => {
  return (
    formData.livingSituationAtAddress !== "buyer" &&
    formData.requestSecuredRate === "true" &&
    formData.ownsInvestmentProperty === "Yes" &&
    formData.investmentPropertyMortgaged === "Yes"
  );
};

export const getDebtListCalloutMessage = (
  formData: UseFormTools<Application>["formData"]
) => {
  if (CONSOLIDATION_PURPOSES.includes(formData.loanPurpose.toLowerCase())) {
    return "Since you're consolidating your debt, include any credit cards or personal loans.";
  }

  const isRefinancing =
    formData.requestSecuredRate === "true" &&
    formData.assetSeller.toLowerCase() === "refinance";
  const hasMortgagedInvestmentProperty =
    getMortgagedInvestmentProperty(formData);

  if (isRefinancing && hasMortgagedInvestmentProperty) {
    return (
      <>
        <p>Since you&apos;ve told us:</p>
        <p>You&apos;re refinancing, include your current vehicle loan.</p>
        <p>Your investment property is mortgaged, include it here.</p>
      </>
    );
  }

  if (isRefinancing || hasMortgagedInvestmentProperty) {
    return (
      <>
        {isRefinancing && (
          <p>
            Since you&apos;re refinancing, include your current vehicle loan.
          </p>
        )}
        {hasMortgagedInvestmentProperty && (
          <p>
            Since you&apos;ve told us your investment property is mortgaged,
            include it here.
          </p>
        )}
      </>
    );
  }
  return null;
};

export const isDebtListButtonDisabled = (
  debtList: DebtListItem[],
  formData: UseFormTools<Application>["formData"],
  formState: UseFormTools<Application>["formState"]
) => {
  if (!formData[debtList[0].type]) {
    return true;
  }

  const isConsolidation = CONSOLIDATION_PURPOSES.includes(
    formData.loanPurpose.toLowerCase()
  );
  const isRefinance =
    formData.requestSecuredRate === "true" &&
    formData.assetSeller.toLowerCase() === "refinance";

  const hasConsolidatedDebt = isConsolidation
    ? debtList.some((listItem) => formData[listItem.consolidate].length > 0)
    : true;

  const hasInvestmentMortgage = getMortgagedInvestmentProperty(formData)
    ? debtList.some((listItem) => {
        return (
          formData[listItem.type] === MORTGAGE_VALUE &&
          formData[listItem.isInvestment].length > 0
        );
      })
    : true;

  const hasRefinancedLoan = isRefinance
    ? debtList.some((listItem) => {
        return (
          formData[listItem.type] === PERSONAL_LOAN_VALUE &&
          formData[listItem.consolidate].length > 0
        );
      })
    : true;

  const isListComplete = debtList.every((listItem) => {
    if (!formData[listItem.type]) {
      return true;
    }
    if (listItem.active && !listItem.selectChanged) {
      return false;
    }
    if (!formData[listItem.financeCompany]) {
      return false;
    }
    if (formData[listItem.type] === CREDIT_CARD_VALUE) {
      if (
        !formData[listItem.creditCardLimit] ||
        formState[listItem.creditCardLimit].errors.length > 0
      ) {
        return false;
      }
      if (
        String(formData[listItem.consolidate][0]).toLowerCase() ===
        "consolidate this card"
      ) {
        return (
          !!formData[listItem.balance] &&
          formState[listItem.balance].errors.length === 0
        );
      }
      return true;
    }
    return (
      formData[listItem.balance] &&
      formState[listItem.balance].errors.length === 0 &&
      formData[listItem.repaymentAmount] &&
      formState[listItem.repaymentAmount].errors.length === 0 &&
      formData[listItem.repaymentFrequency]
    );
  });

  return (
    !hasConsolidatedDebt ||
    !hasInvestmentMortgage ||
    !hasRefinancedLoan ||
    !isListComplete
  );
};

export const addDebtListItem = (
  index: number,
  debtList: DebtListItem[],
  setDebtList: (arg0: DebtListItem[]) => void
) => {
  const list = debtList.slice(0);
  list[index] = { ...list[index], active: true };
  setDebtList(list);
};

export const deleteDebtListItem = (
  id: number,
  debtList: DebtListItem[],
  setDebtList: (arg0: DebtListItem[]) => void,
  setValue: UseFormTools<Application>["setValue"],
  formData: UseFormTools<Application>["formData"]
) => {
  const newList = [...debtList];

  const listItem = newList.find((listItem) => {
    return listItem.id === id;
  });

  if (!listItem) return;

  const listIndex = newList.findIndex((listItem) => {
    return listItem.id === id;
  });

  if (!debtList[listIndex + 1]?.active) {
    setValue(listItem.type, "");
    setValue(listItem.balance, "");
    setValue(listItem.financeCompany, "");
    setValue(listItem.repaymentAmount, "");
    setValue(listItem.repaymentFrequency, "");
    setValue(listItem.creditCardLimit, "");
    setValue(listItem.isJointLoan, []);
    setValue(listItem.consolidate, []);
    setValue(listItem.isInvestment, []);
    setValue(listItem.closeThisCard, []);

    if (listItem.id !== 1) {
      newList[listIndex] = { ...listItem, active: false };
      setDebtList(newList);
    }

    return;
  }

  let currentIndex = listIndex;

  while (debtList[currentIndex + 1]?.active) {
    const currentItem = debtList[currentIndex];
    const nextItem = debtList[currentIndex + 1];
    setValue(currentItem.type, formData[nextItem.type]);
    setValue(currentItem.balance, formData[nextItem.balance]);
    setValue(currentItem.financeCompany, formData[nextItem.financeCompany]);
    setValue(currentItem.repaymentAmount, formData[nextItem.repaymentAmount]);
    setValue(
      currentItem.repaymentFrequency,
      formData[nextItem.repaymentFrequency]
    );
    setValue(currentItem.creditCardLimit, formData[nextItem.creditCardLimit]);
    setValue(currentItem.isJointLoan, formData[nextItem.isJointLoan]);
    setValue(currentItem.consolidate, formData[nextItem.consolidate]);
    setValue(currentItem.isInvestment, formData[nextItem.isInvestment]);
    setValue(currentItem.closeThisCard, formData[nextItem.closeThisCard]);

    newList[currentIndex] = { ...currentItem, active: true };

    currentIndex++;
  }
  const lastActiveItem = debtList[currentIndex];

  setValue(lastActiveItem.type, "");
  setValue(lastActiveItem.balance, "");
  setValue(lastActiveItem.financeCompany, "");
  setValue(lastActiveItem.repaymentAmount, "");
  setValue(lastActiveItem.repaymentFrequency, "");
  setValue(lastActiveItem.creditCardLimit, "");
  setValue(lastActiveItem.isJointLoan, []);
  setValue(lastActiveItem.consolidate, []);
  setValue(lastActiveItem.isInvestment, []);
  setValue(lastActiveItem.closeThisCard, []);
  newList[currentIndex] = { ...lastActiveItem, active: false };
  setDebtList(newList);
};

export const setDebtListSelectChanged = (
  index: number,
  debtList: DebtListItem[],
  setDebtList: (arg0: DebtListItem[]) => void,
  value: boolean
) => {
  const list = debtList.slice(0);
  list[index - 1] = { ...list[index - 1], selectChanged: value };
  setDebtList(list);
};

export const ConsolidateSelect: React.FC<{
  shouldShowHint: boolean;
  formData: UseFormTools<Application>["formData"];
  value: "Yes" | "No" | "";
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isPurposeSecurable: boolean;
  isPersonalLoan: boolean;
}> = ({
  shouldShowHint,
  value,
  onChange,
  formData,
  isPurposeSecurable,
  isPersonalLoan,
}) => {
  const isRefinancing =
    isPersonalLoan &&
    formData.requestSecuredRate === "true" &&
    formData.assetSeller === "refinance";

  if (isPurposeSecurable && !isRefinancing) {
    return null;
  }

  const consolidateLabel = CONSOLIDATION_PURPOSES.includes(
    formData.loanPurpose.toLowerCase()
  )
    ? "Do you want to consolidate this debt?"
    : isRefinancing
    ? "Do you want to refinance this loan?"
    : "Are you interested in consolidating this debt with your new loan?";

  return (
    <>
      <style jsx>{debtListStyle}</style>
      <div className="consolidate-select">
        <Field
          type="radio-button"
          label={consolidateLabel}
          options={["Yes", "No"]}
          tooltipProps={{
            size: "small",
          }}
          tooltip={
            <>
              <p>
                A debt consolidation loan is used to pay off multiple debts.
              </p>
              <p>
                {`This can make it easier to manage your debt
        as you'll only have one loan to worry about.`}
              </p>
              <p>
                It may also reduce your monthly repayments, overall interest and
                fees.
              </p>
            </>
          }
          value={value}
          onChange={onChange}
        />

        {shouldShowHint && !isRefinancing && (
          <div className="hint">
            <StatusCallout
              type="coaching"
              theme="outline"
              message={
                "If your loan is approved, you can use the funds to pay off this debt."
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export const CreditCardDebtQuestions: React.FC<{
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  setValue: UseFormTools<Application>["setValue"];
  debtItem: DebtListItem;
  debtList: DebtListItem[];
  setDebtList: (arg0: DebtListItem[]) => void;
  isPurposeSecurable: boolean;
}> = ({
  fieldAdapter,
  formData,
  setValue,
  debtItem,
  debtList,
  setDebtList,
  isPurposeSecurable,
}) => {
  const [consolidateFieldChanged, setConsolidateFieldChanged] = React.useState(
    (!!formData[debtItem.financeCompany] &&
      !!formData[debtItem.creditCardLimit]) ||
      isPurposeSecurable
  );
  const [closeFieldChanged, setCloseFieldChanged] = React.useState(
    !emptyCurrencyValues.includes(formData[debtItem.balance])
  );
  const hasRestCreditCardQuestions =
    debtItem.active &&
    isRadioValueYes(formData[debtItem.consolidate]) === "Yes";

  React.useEffect(() => {
    if (emptyCurrencyValues.includes(formData[debtItem.balance])) {
      setValue(debtItem.balance, "");
      setCloseFieldChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setDebtListSelectChanged(
      debtItem.id,
      debtList,
      setDebtList,
      consolidateFieldChanged &&
        (!hasRestCreditCardQuestions || closeFieldChanged)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeFieldChanged, consolidateFieldChanged]);

  return (
    <>
      <Field
        type="currency"
        label="Credit card limit"
        hasCommas={true}
        {...fieldAdapter({
          name: debtItem.creditCardLimit,
          required: "Credit card limit is required",
          condition:
            debtItem.active && formData[debtItem.type] === CREDIT_CARD_VALUE,
          validate: (value) =>
            isValidLengthWithoutDecimals(value, 9) ||
            "Credit card limit cannot be more than 9 digits (including 2 decimals)",
        })}
      />
      <ConsolidateSelect
        formData={formData}
        shouldShowHint={
          isRadioValueYes(formData[debtItem.consolidate]) === "Yes"
        }
        value={
          consolidateFieldChanged
            ? getRadioValue(formData[debtItem.consolidate])
            : ""
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setRadioValue(
            e.currentTarget.value,
            setValue,
            debtItem.consolidate,
            "Consolidate this card"
          );
          setConsolidateFieldChanged(true);
        }}
        isPurposeSecurable={isPurposeSecurable}
        isPersonalLoan={false}
      />
      <Field
        type="currency"
        label="Credit card balance"
        hasCommas={true}
        {...fieldAdapter({
          name: debtItem.balance,
          required: "Credit card balance is required",
          condition: hasRestCreditCardQuestions,
          validate: (value) =>
            isValidLengthWithoutDecimals(value, 9) ||
            "Credit card balance cannot be more than 9 digits (including 2 decimals)",
        })}
      />
      <Field
        type="radio-button"
        label="Do you intend to close this card?"
        options={["Yes", "No"]}
        value={
          closeFieldChanged
            ? getRadioValue(formData[debtItem.closeThisCard])
            : ""
        }
        onChange={(e) => {
          setRadioValue(
            e.currentTarget.value,
            setValue,
            debtItem.closeThisCard,
            "Close this card"
          );
          setCloseFieldChanged(true);
        }}
        hide={!hasRestCreditCardQuestions}
      />
    </>
  );
};

export const MortgageDebtInputs: React.FC<{
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  setValue: UseFormTools<Application>["setValue"];
  debtItem: DebtListItem;
  debtList: DebtListItem[];
  setDebtList: (arg0: DebtListItem[]) => void;
}> = ({
  fieldAdapter,
  formData,
  setValue,
  debtItem,
  debtList,
  setDebtList,
}) => {
  const [jointFieldChanged, setJointFieldChanged] = React.useState(
    !emptyCurrencyValues.includes(formData[debtItem.repaymentAmount])
  );
  const [investmentFieldChanged, setInvestmentFieldChanged] = React.useState(
    !emptyCurrencyValues.includes(formData[debtItem.repaymentAmount])
  );

  React.useEffect(() => {
    setDebtListSelectChanged(
      debtItem.id,
      debtList,
      setDebtList,
      jointFieldChanged && investmentFieldChanged
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investmentFieldChanged, jointFieldChanged]);

  return (
    <>
      <style jsx>{style}</style>
      <style jsx>{debtListStyle}</style>
      <div className="questions">
        <Field
          type="currency"
          label="Loan balance"
          hasCommas={true}
          {...fieldAdapter({
            name: debtItem.balance,
            required: "Loan balance is required",
            condition: debtItem.active,
            validate: (value) =>
              isValidLengthWithoutDecimals(value, 10) ||
              "Loan balance cannot be more than 10 digits (including 2 decimals)",
          })}
        />
        <div className="field-pair">
          <Field
            layout="pair"
            type="currency"
            label="Repayment"
            hasCommas={true}
            {...fieldAdapter({
              name: debtItem.repaymentAmount,
              required: "Mortgage repayment is required",
              min: [1, "Mortgage repayment must be greater than 0"],
              validate: (value) =>
                isValidLengthWithoutDecimals(value, 10) ||
                "Mortgage repayment cannot be more than 10 digits (including 2 decimals)",
              condition: debtItem.active,
            })}
          />
          <Field
            layout="pair"
            type="select"
            label="Frequency"
            options={paymentFrequencyOptions}
            placeholder="Select..."
            {...fieldAdapter({
              name: debtItem.repaymentFrequency,
              required: "Mortgage repayment frequency is required",
              condition: debtItem.active,
            })}
          />
        </div>
        <Field
          type="radio-button"
          className="radio-button-top"
          label="Is this a joint loan?"
          options={["Yes", "No"]}
          value={
            jointFieldChanged
              ? getRadioValue(formData[debtItem.isJointLoan])
              : ""
          }
          onChange={(e) => {
            setRadioValue(
              e.currentTarget.value,
              setValue,
              debtItem.isJointLoan,
              "Joint loan"
            );
            setJointFieldChanged(true);
          }}
        />
        <Field
          type="radio-button"
          label="Is this an investment property?"
          options={["Yes", "No"]}
          value={
            investmentFieldChanged
              ? getRadioValue(formData[debtItem.isInvestment])
              : ""
          }
          onChange={(e) => {
            setRadioValue(
              e.currentTarget.value,
              setValue,
              debtItem.isInvestment,
              "Investment property"
            );
            setInvestmentFieldChanged(true);
          }}
        />
      </div>
    </>
  );
};

export const PersonLoanDebtInputs: React.FC<{
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  setValue: UseFormTools<Application>["setValue"];
  debtItem: DebtListItem;
  debtList: DebtListItem[];
  setDebtList: (arg0: DebtListItem[]) => void;
  isPurposeSecurable: boolean;
}> = ({
  fieldAdapter,
  formData,
  setValue,
  debtItem,
  debtList,
  setDebtList,
  isPurposeSecurable,
}) => {
  const [jointFieldChanged, setJointFieldChanged] = React.useState(
    !emptyCurrencyValues.includes(formData[debtItem.repaymentAmount])
  );
  const [consolidateFieldChanged, setConsolidateFieldChanged] = React.useState(
    !emptyCurrencyValues.includes(formData[debtItem.repaymentAmount]) ||
      isPurposeSecurable
  );

  React.useEffect(() => {
    setDebtListSelectChanged(
      debtItem.id,
      debtList,
      setDebtList,
      jointFieldChanged && consolidateFieldChanged
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consolidateFieldChanged, jointFieldChanged]);

  return (
    <>
      <style jsx>{style}</style>
      <style jsx>{debtListStyle}</style>
      <div className="questions">
        <Field
          type="currency"
          label="Loan balance"
          hasCommas={true}
          {...fieldAdapter({
            name: debtItem.balance,
            required: "Loan balance is required",
            condition: debtItem.active,
            validate: (value) =>
              isValidLengthWithoutDecimals(value, 9) ||
              "Loan balance cannot be more than 9 digits (including 2 decimals)",
          })}
        />
        <div className="field-pair">
          <Field
            layout="pair"
            type="currency"
            label="Repayment"
            hasCommas={true}
            {...fieldAdapter({
              name: debtItem.repaymentAmount,
              required: "Personal loan repayment is required",
              min: [1, "Personal loan repayment must be greater than 0"],
              validate: (value) =>
                isValidLengthWithoutDecimals(value, 9) ||
                "Personal loan repayment cannot be more than 9 digits (including 2 decimals)",
              condition: debtItem.active,
            })}
          />
          <Field
            layout="pair"
            type="select"
            label="Frequency"
            options={paymentFrequencyOptions}
            placeholder="Select..."
            {...fieldAdapter({
              name: debtItem.repaymentFrequency,
              required: "Personal loan repayment frequency is required",
              condition: debtItem.active,
            })}
          />
        </div>
        <Field
          type="radio-button"
          className="radio-button-top"
          label="Is this a joint loan?"
          options={["Yes", "No"]}
          value={
            jointFieldChanged
              ? getRadioValue(formData[debtItem.isJointLoan])
              : ""
          }
          onChange={(e) => {
            setRadioValue(
              e.currentTarget.value,
              setValue,
              debtItem.isJointLoan,
              "Joint loan"
            );
            setJointFieldChanged(true);
          }}
        />

        <ConsolidateSelect
          formData={formData}
          shouldShowHint={
            isRadioValueYes(formData[debtItem.consolidate]) === "Yes"
          }
          value={
            consolidateFieldChanged
              ? getRadioValue(formData[debtItem.consolidate])
              : ""
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setRadioValue(
              e.currentTarget.value,
              setValue,
              debtItem.consolidate,
              "Consolidate this loan"
            );
            setConsolidateFieldChanged(true);
          }}
          isPurposeSecurable={isPurposeSecurable}
          isPersonalLoan={true}
        />
      </div>
    </>
  );
};

export const getDebtListDisabledField = (
  fieldAdapter: UseFormTools<Application>["fieldAdapter"]
) => {
  return (
    <>
      <Field
        type="select"
        label="Debt type"
        options={debtTypeOptions}
        placeholder="Select"
        {...fieldAdapter({
          name: debtInitialList[0].type,
          required: "Debt type is required",
          condition: false,
        })}
      />
    </>
  );
};
