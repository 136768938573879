import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Button } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { Application } from "../../../../application";
import { useIsEditing } from "../../../hooks/use-is-editing";
import { getIdentitySource } from "../utils/id-documents.utils";
import documentSubmitStyle from "./document-submit.scss";
import { useSaveLoanApplicationProgress } from "../../../../../redux/loan-application/loan-application.hooks";

interface DocumentSubmitProps extends RouteComponentProps {
  isDisabled: boolean;
  setValue: UseFormTools<Application>["setValue"];
  formData: UseFormTools<Application>["formData"];
  idType: "Passport" | "Medicare card" | "Driver's License";
}

export const DocumentSubmit = ({
  isDisabled,
  setValue,
  formData,
  idType,
  location,
}: DocumentSubmitProps) => {
  const identityIndex = getIdentitySource(formData, idType);
  const isEditing = useIsEditing(location);

  const saveProgress = useSaveLoanApplicationProgress(formData);

  const nextPage = () => {
    setValue(identityIndex, idType);
    saveProgress();
    if (isEditing) {
      navigate("/application/review-your-details");
    } else {
      navigate("/application/id-documents");
    }
  };

  return (
    <div className="button-wrapper">
      <style jsx>{documentSubmitStyle}</style>
      <Button
        button="primary"
        theme="navy"
        size="large"
        fullWidth={true}
        disabled={isDisabled}
      >
        <button
          className="next-page-button"
          onClick={() => nextPage()}
          disabled={isDisabled}
        >
          {isEditing ? "back to review" : "next"}
        </button>
      </Button>
    </div>
  );
};
