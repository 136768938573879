import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Button, Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { Application } from "../../../../application";
import { useIsEditing } from "../../../hooks/use-is-editing";
import style from "../../../multi-page-application-form.scss";
import partnersIncomeStyle from "./partners-income.scss";
import { isValidLengthWithoutDecimals } from "../../../../../redux/loan-application/loan-application.utils";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  formState: UseFormTools<Application>["formState"];
}

export const PartnersIncome = ({
  fieldAdapter,
  formData,
  formState,
  location,
}: PropTypes) => {
  const isDisabled =
    !formData.partnerIncomeAmount ||
    formState.partnerIncomeAmount.errors.length > 0;
  const isEditing = useIsEditing(location);

  const nextPage = () => {
    if (isEditing) {
      navigate("/application/review-your-details");
    } else {
      navigate("/application/other-income");
    }
  };

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{partnersIncomeStyle}</style>

        <div className="form-contents">
          <div>
            <div className="heading-wrapper">
              <h2 className="heading">Partner&apos;s Income</h2>
              <p>This helps us understand your financial situation better.</p>
            </div>

            <div className="input-wrapper">
              <Field
                label="Partner's total annual income (before tax)"
                type="currency"
                hasCommas={true}
                {...fieldAdapter({
                  name: "partnerIncomeAmount",
                  min: [0],
                  validate: (value) =>
                    isValidLengthWithoutDecimals(value, 9) ||
                    "Annual income amount cannot be more than 9 digits (including 2 decimals)",
                })}
              />
            </div>
          </div>

          <div className="button-wrapper">
            <Button
              button="primary"
              theme="navy"
              size="large"
              fullWidth={true}
              disabled={isDisabled}
            >
              <button
                className="next-page-button"
                onClick={() => nextPage()}
                disabled={isDisabled}
              >
                {isEditing ? "back to review" : "next"}
              </button>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
