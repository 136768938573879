import { UseFormTools } from "@wisr/common";

import { Application } from "../../../application";
import { ContactLabels } from "./living-situation.constants";

export const getRentSharedValue = (rentShared: string[]) => {
  if (rentShared.length > 0) {
    return "Yes";
  }
  return "No";
};

export const setRentShared = (
  value: string,
  setValue: UseFormTools<Application>["setValue"]
) => {
  if (value === "Yes") {
    setValue("rentShared", ["Total rental amount is shared?"]);
  } else {
    setValue("rentShared", []);
  }
};

export const getContactLabels = (livingSituation: string) => {
  switch (livingSituation) {
    case "living-with-parents":
      return ContactLabels.PARENTS;
    case "boarder":
      return ContactLabels.BOARDING;
    case "employer":
      return ContactLabels.EMPLOYER;
    default:
      return ContactLabels.BOARDING;
  }
};

const employerNumberPattern = /^0\d{3} \d{3} \d{3}$/;

export const isLivingSituationButtonDisabled = (
  formData: UseFormTools<Application>["formData"],
  formState: UseFormTools<Application>["formState"],
  rentSharedChanged?: boolean
) => {
  switch (formData.livingSituationAtAddress) {
    case "":
      return true;
    case "renter":
      return (
        !(formData.totalRentalAmount && formData.rentalAmountFrequency) ||
        formState.totalRentalAmount.errors.length > 0 ||
        formData.totalRentalAmount === "0" ||
        !rentSharedChanged
      );
    case "buyer":
      return (
        !(
          formData.mortgageFinanceCompany &&
          formData.mortgageBalance &&
          formData.mortgageRepaymentAmount &&
          formData.mortgageRepaymentFrequency &&
          formData.hasCoBorrower
        ) ||
        formState.mortgageBalance.errors.length > 0 ||
        formState.mortgageRepaymentAmount.errors.length > 0 ||
        formData.mortgageBalance === "0" ||
        formData.mortgageRepaymentAmount === "0"
      );
    case "boarder":
    case "living-with-parents":
    case "employer":
      return !(
        formData.propertyOwnerManagerName &&
        formData.propertyOwnerManagerPhone &&
        formData.propertyOwnerManagerPhone.search(employerNumberPattern) !== -1
      );
    default:
      return false;
  }
};
