import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";
import { ContextCard } from "../../../../context-card/context-card";
import { ContextCardApplicationCopies } from "../../../../context-card/context-card.constants";
import { AppFormPages } from "../../../../shared/analytics/analytics.consts";

import { Application } from "../../../application";
import { HelpCallout } from "../../help-callout/help-callout";
import { InfoModal } from "../../info-modal/info-modal";
import style from "../../multi-page-application-form.scss";
import {
  getIsPurposeSecurableByKey,
  sendAppFormViewedEvent,
} from "../../multi-page-application-form.utils";
import { getDebtListDisabledField } from "./debt-list/debt-list.utils";
import yourDebtsStyle from "./your-debts.scss";
import { useGetLoanParameters } from "../../../../loans/parameters/loan-parameters.hooks";
import { CONSOLIDATION_PURPOSES } from "../../../../redux/loan-application/loan-application.constants";

type PropTypes = RouteComponentProps & {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  setValue: UseFormTools<Application>["setValue"];
};

const MODAL_HEADING = "What is considered debt?";

export const YourDebts: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  setValue,
}) => {
  const loanParameters = useGetLoanParameters();
  const isPurposeSecurable = getIsPurposeSecurableByKey(
    formData.loanPurpose,
    loanParameters.loanPurpose
  );
  const [infoModal, setInfoModal] = React.useState(false);
  const [fieldValue, setFieldValue] = React.useState("");
  const mortgageCopy =
    (formData.ownsInvestmentProperty.toLowerCase() === "yes" &&
      formData.investmentPropertyMortgaged.toLowerCase() === "yes") ||
    formData.livingSituationAtAddress === "buyer"
      ? "other mortgages"
      : "mortgages";

  const modalDescription = (
    <>
      <style jsx>{yourDebtsStyle}</style>
      <p>Debt is any money you owe to a lender or someone else.</p>
      <p>Examples include:</p>
      <ul>
        <li>Credit cards</li>
        <li>Mortgages</li>
        <li>Personal loans</li>
        <li>Student loans</li>
        <li>Payday loans</li>
        <li>Overdrafts</li>
      </ul>
    </>
  );

  const contextCopy = CONSOLIDATION_PURPOSES.includes(formData.loanPurpose)
    ? ContextCardApplicationCopies.ROLL_IT_CONSOLIDATION
    : ContextCardApplicationCopies.ROLL_IT_UNSECURED;

  const hiddenDebtListField = getDebtListDisabledField(fieldAdapter);

  React.useEffect(() => {
    if (fieldValue !== "") {
      setFieldValue("");
      if (fieldValue === "yes") {
        setValue("hasDebts", "yes");
        navigate("/application/debt-list");
      } else if (fieldValue === "no") {
        setValue("hasDebts", "no");
        navigate("/application/your-bank-statements");
      }
    }
  }, [fieldValue, setValue]);

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.DEBTS);
  }, []);

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{yourDebtsStyle}</style>

        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">
              Do you have any debts, such as credit cards, overdrafts,{" "}
              {mortgageCopy} or loans?
            </h2>
          </div>
          <p className="content">
            Help us process your loan faster by declaring all your debts.
          </p>
          <div className="callout">
            <HelpCallout
              text="What is considered debt?"
              setInfoModal={setInfoModal}
            />
          </div>
          {hiddenDebtListField}
          {!isPurposeSecurable ? (
            <ContextCard copy={contextCopy} form="application" />
          ) : null}
        </div>

        <Field
          type="radio-list"
          label=""
          options={[
            { label: "Yes, I do", value: "yes" },
            { label: "No, I don't", value: "no" },
          ]}
          onChange={(e) => {
            setFieldValue(e.currentTarget.value);
          }}
        />
      </div>
      <InfoModal
        infoModal={infoModal}
        setInfoModal={setInfoModal}
        heading={MODAL_HEADING}
        description={modalDescription}
      />
    </>
  );
};
