import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { ContextCard } from "../../../../context-card/context-card";
import { ContextCardApplicationCopies } from "../../../../context-card/context-card.constants";
import { AppFormPages } from "../../../../shared/analytics/analytics.consts";

import { Application } from "../../../application";
import { HelpCallout } from "../../help-callout/help-callout";
import { useIsEditing } from "../../hooks/use-is-editing";
import { InfoModal } from "../../info-modal/info-modal";
import style from "../../multi-page-application-form.scss";
import { sendAppFormViewedEvent } from "../../multi-page-application-form.utils";
import {
  clearOtherIncomeList,
  getOtherIncomeDisabledFields,
} from "../other-income-list/other-income-list.utils";
import otherIncomeStyle from "./other-income.scss";

interface PropTypes extends RouteComponentProps {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  setValue: UseFormTools<Application>["setValue"];
}

const MODAL_HEADING = "What are other sources of income?";

export const OtherIncome: React.FC<PropTypes> = ({
  fieldAdapter,
  setValue,
  location,
}) => {
  const [infoModal, setInfoModal] = React.useState(false);
  const [fieldValue, setFieldValue] = React.useState("");
  const isEditing = useIsEditing(location);

  const modalDescription = (
    <>
      <style jsx>{otherIncomeStyle}</style>
      <p>
        This is any money you earn outside of your main source of income,
        including:
      </p>
      <ul>
        <li>Casual or self-employment income</li>
        <li>Rental income</li>
        <li>Investment income (interest & dividends)</li>
        <li>Government benefits </li>
      </ul>
    </>
  );

  const hiddenOtherIncomeFields = getOtherIncomeDisabledFields(fieldAdapter);

  React.useEffect(() => {
    if (fieldValue !== "") {
      setFieldValue("");
      if (fieldValue === "Yes") {
        setValue("hasOtherIncomes", "yes");
        navigate("/application/other-income-list");
      } else if (fieldValue === "No") {
        setValue("hasOtherIncomes", "no");
        clearOtherIncomeList(setValue);
        if (isEditing) {
          navigate("/application/review-your-details");
        } else {
          navigate("/application/your-spending");
        }
      }
    }
  }, [fieldValue, isEditing, setValue]);

  React.useEffect(() => {
    sendAppFormViewedEvent(AppFormPages.OTHER_INCOME);
  }, []);

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{otherIncomeStyle}</style>

        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">
              Do you earn income from any other sources on top of your primary
              employment?
            </h2>
          </div>
          <div className="callout">
            <HelpCallout
              text="What are other sources of income?"
              setInfoModal={setInfoModal}
            />
          </div>
          {hiddenOtherIncomeFields}
        </div>
        <ContextCard
          copy={ContextCardApplicationCopies.HALF_WAY_THERE}
          form="application"
        />

        <Field
          type="radio-list"
          label=""
          options={["Yes", "No"]}
          onChange={(e) => {
            setFieldValue(e.currentTarget.value);
          }}
        />
      </div>

      <InfoModal
        infoModal={infoModal}
        setInfoModal={setInfoModal}
        heading={MODAL_HEADING}
        description={modalDescription}
      />
    </>
  );
};
