import { UseFormTools } from "@wisr/common";
import { Field } from "@wisr/react-ui";
import React from "react";

import { Application } from "../../../application";
import {
  incomeSourceOptions,
  otherIncomeInitialList,
  OtherIncomeListItem,
} from "./other-income-list.constants";
import { paymentFrequencyOptions } from "../../../application-form.constants";

export const getOtherIncomeInitialValues = (
  formData: UseFormTools<Application>["formData"]
) => {
  return otherIncomeInitialList.map((listItem) => {
    if (formData[listItem.source]) {
      return { ...listItem, active: true };
    }
    return listItem;
  });
};

export const addIncomeListItem = (
  index: number,
  otherIncomeList: OtherIncomeListItem[],
  setOtherIncomeList: (arg0: OtherIncomeListItem[]) => void
) => {
  const list = otherIncomeList.slice(0);
  list[index] = { ...list[index], active: true };
  setOtherIncomeList(list);
};

export const deleteIncomeListItem = (
  id: number,
  otherIncomeList: OtherIncomeListItem[],
  setOtherIncomeList: (arg0: OtherIncomeListItem[]) => void,
  setValue: UseFormTools<Application>["setValue"],
  formData: UseFormTools<Application>["formData"]
) => {
  const list = otherIncomeList.slice(0);
  const listItem = list.find((listItem) => {
    return listItem.id === id;
  });
  const listIndex = list.findIndex((listItem) => {
    return listItem.id === id;
  });

  if (!listItem) return;

  const nextItem = otherIncomeList[listIndex + 1];
  if (nextItem && nextItem.active === true) {
    setValue(listItem.source, formData[nextItem.source]);
    setValue(listItem.amount, formData[nextItem.amount]);
    setValue(listItem.frequency, formData[nextItem.frequency]);

    list[listIndex] = { ...listItem, active: true };

    const nextNextItem = otherIncomeList[listIndex + 2];
    if (nextNextItem && nextNextItem.active === true) {
      setValue(nextItem.source, formData[nextNextItem.source]);
      setValue(nextItem.amount, formData[nextNextItem.amount]);
      setValue(nextItem.frequency, formData[nextNextItem.frequency]);

      setValue(nextNextItem.source, "");
      setValue(nextNextItem.amount, "");
      setValue(nextNextItem.frequency, "");

      list[listIndex + 1] = { ...nextItem, active: true };
      list[listIndex + 2] = { ...nextNextItem, active: false };
    } else {
      setValue(nextItem.source, "");
      setValue(nextItem.amount, "");
      setValue(nextItem.frequency, "");

      list[listIndex + 1] = { ...nextItem, active: false };
    }

    setOtherIncomeList(list);
    return;
  }

  setValue(listItem.source, "");
  setValue(listItem.amount, "");
  setValue(listItem.frequency, "");

  if (listItem.id !== 1) {
    list[listIndex] = { ...listItem, active: false };
    setOtherIncomeList(list);
  }
};

export const isOtherIncomeButtonDisabled = (
  otherIncomeList: OtherIncomeListItem[],
  formData: UseFormTools<Application>["formData"],
  formState: UseFormTools<Application>["formState"]
) => {
  if (!formData[otherIncomeList[0].source]) {
    return true;
  }

  const isListComplete = otherIncomeList.every((listItem) => {
    if (formData[listItem.source]) {
      return (
        formData[listItem.amount] &&
        formData[listItem.frequency] &&
        formState[listItem.amount].errors.length === 0
      );
    } else {
      return true;
    }
  });

  return !isListComplete;
};

export const clearOtherIncomeList = (
  setValue: UseFormTools<Application>["setValue"]
) => {
  otherIncomeInitialList.forEach((otherIncomeItem) => {
    setValue(otherIncomeItem.source, "");
    setValue(otherIncomeItem.amount, "");
    setValue(otherIncomeItem.frequency, "");
  });
};

export const getOtherIncomeDisabledFields = (
  fieldAdapter: UseFormTools<Application>["fieldAdapter"]
) => {
  return otherIncomeInitialList.map((incomeItem) => {
    return (
      <>
        <Field
          type="select"
          label="Income source"
          options={incomeSourceOptions}
          placeholder="Select"
          {...fieldAdapter({
            name: incomeItem.source,
            required: "Income source is required",
            condition: false,
          })}
        />
        <Field
          type="currency"
          label="Income (before tax)"
          layout="pair"
          hasCommas={true}
          {...fieldAdapter({
            name: incomeItem.amount,
            required: "Income amount is required",
            min: [1],
            maxLength: [10, "Income amount cannot be more than 10 digits"],
            condition: false,
          })}
        />
        <Field
          type="select"
          label="Frequency"
          layout="pair"
          options={paymentFrequencyOptions}
          placeholder="Select"
          {...fieldAdapter({
            name: incomeItem.frequency,
            required: "Income frequency is required",
            condition: false,
          })}
        />
      </>
    );
  });
};
