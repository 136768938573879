import { WindowLocation } from "@gatsbyjs/reach-router";

import { useQueryString } from "../../../shared/gatsby.utils";
import { GatsbyPageLocation } from "../../../types/gatsby";
import { validLoanTerms } from "../../../redux/loan-application/loan-application.constants";

export const useIsEditing = (
  location?: GatsbyPageLocation | WindowLocation<unknown>
) => {
  const params = useQueryString(location);
  return params.editing === "true";
};

export const useLoanTerm = (
  location?: GatsbyPageLocation | WindowLocation<unknown>
) => {
  const params = useQueryString(location);
  return params.term &&
    params.term !== null &&
    validLoanTerms.includes(params.term.toString())
    ? params.term.toString()
    : null;
};
