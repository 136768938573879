import { Router } from "@gatsbyjs/reach-router";
import { isBrowser, isBrowserMobile, UseFormTools } from "@wisr/common";
import { navigate, withPrefix } from "gatsby";
import React from "react";

import { Application } from "../application";
import { BankStatements } from "./form-pages/bank-statements/bank-statements";
import { Dependants } from "./form-pages/dependants/dependants";
import { EmploymentAndIncome } from "./form-pages/employment-and-income/employment-and-income";
import { PartnersIncome } from "./form-pages/employment-and-income/partners-income/partners-income";
import { PreviousEmployment } from "./form-pages/employment-and-income/previous-employment/previous-employment";
import { DriversLicence } from "./form-pages/id-documents/drivers-licence/drivers-licence";
import { IdDocuments } from "./form-pages/id-documents/id-documents";
import { Medicare } from "./form-pages/id-documents/medicare/medicare";
import { Passport } from "./form-pages/id-documents/passport/passport";
import { InvestmentProperty } from "./form-pages/investment-property/investment-property";
import { InvestmentPropertyMortgaged } from "./form-pages/investment-property-mortgaged/investment-property-mortgaged";
import { LivingSituation } from "./form-pages/living-situation/living-situation";
import { OtherIncome } from "./form-pages/other-income/other-income";
import { OtherIncomeList } from "./form-pages/other-income-list/other-income-list";
import { ReviewYourDetails } from "./form-pages/review-your-details/review-your-details";
import { SignificantChanges } from "./form-pages/significant-changes/significant-changes";
import { TypeOfChanges } from "./form-pages/significant-changes/type-of-changes/type-of-changes";
import { StartApplication } from "./form-pages/start-application/start-application";
import { DebtList } from "./form-pages/your-debts/debt-list/debt-list";
import { YourDebts } from "./form-pages/your-debts/your-debts";
import { AdditionalExpenses } from "./form-pages/your-spending/additional-expenses/additional-expenses";
import { YourSpending } from "./form-pages/your-spending/your-spending";
import { LoanTerm } from "./form-pages/loan-term/loan-term";
import { useDispatch } from "react-redux";
import { useGetApplicationTermsState } from "../../redux/application-terms/application-terms.hooks";
import { FetchStatus } from "../../shared/redux.consts";
import { fetchApplicationTerms } from "../../redux/application-terms/application-terms.thunk";
import { Requirements } from "./form-pages/requirements/requirements";

interface PropTypes {
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
  formData: UseFormTools<Application>["formData"];
  setValue: UseFormTools<Application>["setValue"];
  formState: UseFormTools<Application>["formState"];
  submitAdapter: UseFormTools<Application>["submitAdapter"];
  setFormErrors: (arg0: string[]) => void;
}

export const MultiPageApplicationForm: React.FC<PropTypes> = ({
  fieldAdapter,
  formData,
  setValue,
  formState,
  submitAdapter,
  setFormErrors,
}) => {
  const dispatch = useDispatch();
  const applicationTermsState = useGetApplicationTermsState();
  const routerStyle = {
    display: "flex",
    flexGrow: 1,
    paddingBottom: "32px",
    ...(!isBrowserMobile() && { alignItems: "flex-start" }),
  };

  const requirementsAndObjectives = isBrowser()
    ? window.sessionStorage.getItem("requirementsAndObjectives")
    : null;

  React.useEffect(() => {
    if (typeof requirementsAndObjectives === "string") {
      navigate("/application/dependants");
    }
  }, [requirementsAndObjectives, setValue]);

  React.useEffect(() => {
    if (applicationTermsState.status === FetchStatus.IDLE) {
      dispatch(fetchApplicationTerms());
    }
  }, [applicationTermsState.status, dispatch]);

  return (
    <Router basepath={withPrefix("/application")} style={routerStyle}>
      <StartApplication
        path="/start-application"
        default={true}
        formData={formData}
      />

      <Requirements
        path="/requirements"
        fieldAdapter={fieldAdapter}
        formData={formData}
        formState={formState}
      />

      <Dependants
        path="/dependants"
        fieldAdapter={fieldAdapter}
        formData={formData}
        setValue={setValue}
        formState={formState}
      />

      <IdDocuments
        path="/id-documents"
        fieldAdapter={fieldAdapter}
        formData={formData}
        setValue={setValue}
      />

      <DriversLicence
        path="/drivers-licence"
        fieldAdapter={fieldAdapter}
        formData={formData}
        setValue={setValue}
        formState={formState}
      />

      <Passport
        path="/passport"
        fieldAdapter={fieldAdapter}
        formData={formData}
        setValue={setValue}
        formState={formState}
      />

      <Medicare
        path="/medicare"
        fieldAdapter={fieldAdapter}
        formData={formData}
        setValue={setValue}
        formState={formState}
      />

      <LivingSituation
        path="/living-situation"
        fieldAdapter={fieldAdapter}
        formData={formData}
        setValue={setValue}
        formState={formState}
      />

      <InvestmentProperty path="/investment" setValue={setValue} />

      <InvestmentPropertyMortgaged
        path="/investment-mortgaged"
        setValue={setValue}
      />

      <EmploymentAndIncome
        path="/employment-and-income"
        fieldAdapter={fieldAdapter}
        formData={formData}
        formState={formState}
      />

      <PreviousEmployment
        path="/previous-employment-and-income"
        fieldAdapter={fieldAdapter}
        formData={formData}
      />

      <PartnersIncome
        path="/partners-income"
        fieldAdapter={fieldAdapter}
        formData={formData}
        formState={formState}
      />

      <OtherIncome
        path="/other-income"
        fieldAdapter={fieldAdapter}
        setValue={setValue}
      />

      <OtherIncomeList
        path="/other-income-list"
        fieldAdapter={fieldAdapter}
        formData={formData}
        formState={formState}
        setValue={setValue}
      />

      <YourSpending
        path="/your-spending"
        fieldAdapter={fieldAdapter}
        formData={formData}
        formState={formState}
      />

      <AdditionalExpenses
        path="/additional-expenses"
        fieldAdapter={fieldAdapter}
        formData={formData}
        formState={formState}
        setValue={setValue}
      />

      <YourDebts
        path="/your-debts"
        fieldAdapter={fieldAdapter}
        formData={formData}
        setValue={setValue}
      />

      <DebtList
        fieldAdapter={fieldAdapter}
        path="/debt-list"
        formData={formData}
        setValue={setValue}
        formState={formState}
      />

      <BankStatements
        path="/your-bank-statements"
        fieldAdapter={fieldAdapter}
        setValue={setValue}
        formData={formData}
      />

      <SignificantChanges
        path="/significant-changes"
        setValue={setValue}
        formData={formData}
      />

      <TypeOfChanges
        path="/type-of-changes"
        fieldAdapter={fieldAdapter}
        formData={formData}
      />

      <ReviewYourDetails
        path="/review-your-details"
        fieldAdapter={fieldAdapter}
        formData={formData}
        setValue={setValue}
        submitAdapter={submitAdapter}
        setFormErrors={setFormErrors}
      />

      <LoanTerm path="/loan-term" formData={formData} setValue={setValue} />
    </Router>
  );
};
