import { useSelector } from "react-redux";

import { AppStore } from "../store";
import { Aggregator } from "./aggregator";

export const useAggregator = () => {
  return useSelector<AppStore, Aggregator | undefined>(
    (store) => store.aggregatorState.aggregator
  );
};

export const useBid = () => {
  return useSelector<AppStore, string | undefined>(
    (store) => store.aggregatorState.bid
  );
};
