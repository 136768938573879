/* istanbul ignore file - graphql/page query cant be reliably tested */

import { graphql } from "gatsby";

import { ApplicationFormComponent } from "../../application/application-form.component";

export default ApplicationFormComponent;

export const query = graphql`
  query ApplicationFormPage {
    commonValues {
      purposes {
        code
        description
        secureable
        validFor7YearTerm
      }
      residency {
        code
        description
      }
      states {
        code
        description
      }
      employment {
        code
        description
      }
      title {
        code
        description
      }
      streetTypes {
        code
        description
        common
      }
      assetAgeBands {
        label
        value
        assumedAssetAge
        rateType
      }
      minEligibleIncome
    }
  }
`;
