export const LoanApplicationStatus = {
  NOT_SUBMITTED: "NotSubmitted",
  SAVING: "Saving",
  SAVED: "Saved",
  PENDING: "Pending",
  PRE_SUBMIT_NSR_FAILED: "PreSubmitNSRFailed",
  AWAITING_ASSESSMENT: "AwaitingAssessment",
  CREDIT_ASSESSMENT: "CreditAssessment",
  VERIFICATIONS: "Verifications",
  APPROVED: "Approved",
  READY_TO_SETTLE: "ReadyToSettle",
  SETTLEMENT_IN_PROGRESS: "SettlementInProgress",
  SETTLED: "Settled",
  WITHDRAWN: "Withdrawn",
  DECLINED: "Declined",
};

export const notSubmittedStates = [
  LoanApplicationStatus.NOT_SUBMITTED,
  LoanApplicationStatus.SAVING,
  LoanApplicationStatus.SAVED,
];

export const assessmentStates = [
  LoanApplicationStatus.AWAITING_ASSESSMENT,
  LoanApplicationStatus.CREDIT_ASSESSMENT,
];
