import { RouteComponentProps } from "@gatsbyjs/reach-router";
import { UseFormTools } from "@wisr/common";
import { Button, Field } from "@wisr/react-ui";
import { navigate } from "gatsby";
import React from "react";

import { Application } from "../../../../application";
import { useIsEditing } from "../../../hooks/use-is-editing";
import style from "../../../multi-page-application-form.scss";
import typeOfChangesStyle from "./type-of-changes.scss";
import { useSaveLoanApplicationProgress } from "../../../../../redux/loan-application/loan-application.hooks";

interface PropTypes extends RouteComponentProps {
  formData: UseFormTools<Application>["formData"];
  fieldAdapter: UseFormTools<Application>["fieldAdapter"];
}

export const TypeOfChanges: React.FC<PropTypes> = ({
  formData,
  fieldAdapter,
  location,
}) => {
  const saveProgress = useSaveLoanApplicationProgress(formData);
  const isEditing = useIsEditing(location);

  const nextPage = () => {
    saveProgress();
    navigate("/application/review-your-details");
  };

  const isDisabled = !formData.significantPlannedChangeTypes.length;

  return (
    <>
      <div className="multi-page-application">
        <style jsx>{style}</style>
        <style jsx>{typeOfChangesStyle}</style>

        <div className="form-contents">
          <div className="heading-wrapper">
            <h2 className="heading">What type of changes do you expect?</h2>
          </div>

          <div className="input-wrapper">
            <Field
              type="checkbox"
              label=""
              variation={"boxed"}
              options={[
                {
                  groupHeading: "Short-term (less than 6 months)",
                  label: "Increase in expenses",
                  value: "short_term_increase_in_expenses",
                },
                {
                  groupHeading: "Short-term (less than 6 months)",
                  label: "Decrease in income",
                  value: "short_term_decrease_in_income",
                },
                {
                  groupHeading: "Long-term (more than 6 months)",
                  label: "Increase in expenses",
                  value: "long_term_increase_in_expenses",
                },
                {
                  groupHeading: "Long-term (more than 6 months)",
                  label: "Decrease in income",
                  value: "long_term_decrease_in_income",
                },
              ]}
              {...fieldAdapter({
                name: "significantPlannedChangeTypes",
                required:
                  formData.significantPlannedChanges === "yes"
                    ? "Select at least one type of change"
                    : false,
                condition: formData.significantPlannedChanges === "yes",
              })}
            ></Field>
          </div>
        </div>
        <div className="button-wrapper">
          <Button
            button="primary"
            theme="navy"
            size="large"
            fullWidth={true}
            disabled={isDisabled}
          >
            <button
              className="next-page-button"
              onClick={() => nextPage()}
              disabled={isDisabled}
            >
              {isEditing ? "back to review" : "next"}
            </button>
          </Button>
        </div>
      </div>
    </>
  );
};
