export interface ValidPasswordResult {
  validLength: boolean;
  hasCapital: boolean;
  hasLower: boolean;
  hasNumber: boolean;
  hasSymbol: boolean;
}

export const isValidPassword = (password: string): ValidPasswordResult => {
  const validLength = password.length >= 8;
  const hasCapital = capitalCheck.test(password);
  const hasLower = lowerCheck.test(password);
  const hasNumber = numberCheck.test(password);
  const hasSymbol = symbolCheck.test(password);

  return {
    validLength: validLength,
    hasCapital: hasCapital,
    hasLower: hasLower,
    hasNumber: hasNumber,
    hasSymbol: hasSymbol,
  };
};

const capitalCheck = /.*[A-Z].*/;
const lowerCheck = /.*[a-z].*/;
const numberCheck = /.*\d.*/;
const symbolCheck = /.*[~!@#$%^&*()_+`\-={}[\]:";',./<>?|\\].*/;
